import {
  Box, Skeleton, useTheme,
} from '@mui/material';

interface skeletonProps {
  numberOfSkeletons: number;
}

export function PartnerCardSkeleton({ numberOfSkeletons }: skeletonProps) {
  const theme = useTheme();
  const skeletons = Array.from({ length: numberOfSkeletons });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(6),
      }}
    >
      {skeletons.map((_, index) => (
        <Box key={index} sx={{
          background: theme.palette.common.white,
          borderRadius: theme.spacing(1.5),
          padding: `${theme.spacing(3)} ${theme.spacing(3.5)}`,
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: theme.spacing(4),
          }}>
            {/* Icon Skeleton */}
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={80}
              width={80}
              style={{ marginRight: theme.spacing(2) }}
            />
            {/* Title Skeleton */}
            <Skeleton
              animation="wave"
              height={50}
              width="60%"
            />
            {/* Enrollment Skeleton */}
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={80}
              width={200}
              style={{ marginLeft: theme.spacing(4), marginRight: theme.spacing(2) }}
            />
          </Box>
          {/* Subtitle Skeleton */}
          <Skeleton
            animation="wave"
            height={60}
            width="80%"
            style={{ marginBottom: theme.spacing(3) }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Skeleton variant="rectangular" height={36} width="100%" style={{ borderRadius: theme.spacing(1) }} />
            <Skeleton variant="rectangular" height={36} width="100%" style={{ borderRadius: theme.spacing(1) }} />
            <Skeleton variant="rectangular" height={36} width="100%" style={{ borderRadius: theme.spacing(1) }} />
          </Box>
        </Box>
      ))}
    </Box>
  );
}
