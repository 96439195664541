import React from 'react';
import {
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CallToActionButton from '../../../common/CallToActionButton';
import { HOME_DEPOT_SETUP_OR_SKIP_ROUTE, HOME_DEPOT_SETUP_WEB } from '../../../routes/namedRoutes';
import { ReactComponent as ClockIcon } from '../../../assets/images/clock.svg';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';
import { useSolutionOrg } from '../../../config/useSolutionOrg';

interface HDSetupStartCardProps {
  dashboardLayout?: boolean;
}

export default function HDSetupStartCard({ dashboardLayout = false }: HDSetupStartCardProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const trackOnboardEvent = useOnboardEventTracking();
  const { isIframe } = useSolutionOrg();

  return (
    <Box >
      <Box
        sx={{
          margin: theme.spacing(2, 0),
          color: theme.palette.grey56.main,
        }}
      >
        <Typography variant='body1'>{t('hdSetUpWeb.start.description')}</Typography>
      </Box>
      <Box
        sx={{
          display: 'block',
          textAlign: 'center',
          ...(!dashboardLayout && {
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              alignItems: 'baseline',
            },
          }),
        }}>

        <CallToActionButton
          text={`${t('hdSetUpWeb.start.cta')}`}
          type={'button'}
          onClick={() => {
            trackOnboardEvent('clicked-hd-card-start-enrollment');
            navigate(isIframe ? HOME_DEPOT_SETUP_OR_SKIP_ROUTE : `${HOME_DEPOT_SETUP_WEB}?fromRewards`, {
              state: { fromRewards: true },
            });
          }}
          optionalStackStyles={{
            display: 'inline',
          }}
          sx={{
            fontSize: '16px !important',
            fontWeight: '700 !important',
            background: theme.palette.secondary.main,
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            position: 'static',
            borderRadius: theme.spacing(3.75),
            boxShadow: 'none',
            height: 'auto',
            width: 'auto!important',
            padding: `${theme.spacing(1.5, 4)}`,
            margin: `${theme.spacing(0)}!important`,
            [theme.breakpoints.up('lg')]: {
              position: 'static',
              marginRight: 'auto',
              maxWidth: '500px',
            },
          }}
        />
        <Box
          sx={{
            marginTop: theme.spacing(1),
          }}
        >
          <Typography
            display={'inline'}
            sx={{
              color: theme.palette.grey56.main,
              fontSize: '16px',
            }}
          >
            <ClockIcon style={{
              verticalAlign: 'text-top',
              margin: theme.spacing(0, 0.5),
            }} />
            {t('hdSetUpWeb.start.timeEstimate', { minutes: '2-3' })}
          </Typography>
        </Box>
      </Box>
    </Box >
  );
}
