import {
  Container, SxProps, Theme, useTheme,
} from '@mui/material';

interface OuterContainerProps {
  children: JSX.Element;
  sx?: SxProps<Theme>;
}

const outerContainerStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  flex: '1 0 0',
  background: `${theme.palette.common.white}`,
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
    borderRadius: theme.spacing(1),
    border: `${theme.spacing(0.125)} solid ${theme.palette.grey3.main}`,
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 0px rgba(0, 0, 0, 0.00)',
  },
} as const);

export function OuterContainer({ children, sx }: OuterContainerProps) {
  const theme = useTheme();

  return (
    <Container
      sx={{
        ...outerContainerStyle(theme),
        ...sx,
      }}
    >
      {children}
    </Container>
  );
}
