import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React from 'react';
import PaypalLoginLogo from '../assets/images/paypal_white_logo.png';
import theme from '../assets/theme';

const ColorButton = styled(Button)<ButtonProps>(() => ({
  position: 'static',
  color: theme.palette.getContrastText('#FE2C55'),
  borderRadius: theme.spacing(1.25),
  height: 55,
  top: '45vh',
  width: '90%',
  textTransform: 'none',
  backgroundColor: theme.palette.paypalBtnColor.main,
  '&:hover': {
    backgroundColor: theme.palette.paypalBtnColor.main,
  },
  '&.MuiButton-contained': {
    fontWeight: 600,
    fontSize: theme.spacing(2),
  },
  '&:disabled': {
    backgroundColor: theme.palette.paypalBtnColor.main,
    opacity: '0.4',
    color: theme.palette.common.white,
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: '5vw',
    width: '40%',
  },
}));

type ConnectWithPaypalCTAProps = {
  text?: string | JSX.Element;
  showLoader?: boolean;
  disable?: boolean;
  onBtnClick?: () => void;
  optionalStackStyles?: {
    [key: string]: unknown
  },
} & ButtonProps;

export default function ConnectWithPaypalCTA({
  text,
  showLoader,
  disable = false,
  onBtnClick,
  optionalStackStyles,
  ...props
}: ConnectWithPaypalCTAProps) {
  const handleOnClick = () => {
    if (onBtnClick) {
      onBtnClick();
    }
  };
  return (
    <Stack spacing={2} direction="row">
      <ColorButton variant="contained" onClick={handleOnClick} {...props} disabled={disable}>
        {!showLoader &&
          <img src={PaypalLoginLogo} alt="DisabledShareIcon"
            style={{
              marginRight: theme.spacing(1),
            }}
          />
        }
        {text}
      </ColorButton>
    </Stack>
  );
}
