/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Alert, Snackbar, Box, Typography, TextField, Theme, useMediaQuery, useTheme, Checkbox,
  Grid,
  Divider,
  Link,
  Button,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { CreateOrUpdatePaymentMethodInput, VerifyMemberPaypalIdentityInput } from '@optimus/models';
import { unwrapResult } from '@reduxjs/toolkit';
import _isEqual from 'lodash/isEqual';
import CallToActionButton from '../../common/CallToActionButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectGetPayPalAccountState, selectPayPalAccount, selectSetPayPalAccountIdState,
} from '../../store/slices/dashboardSlice';
import { getPayPalAccount, setPayPalAccountId, verifyAndUpdatePaypalEmail } from '../../store/thunks/dashboardThunk';
import { selectMemberInformation, selectMemberSession } from '../../store/slices/onboardingSlice';
import { resendVerficationEmail } from '../../api/dashboardAPI';
import { JoistSolutionOrgName, OnboardingEventType, emailRegex } from '../../common/constants';
import { useTracking } from '../../common/useTracking';
import { useSolutionOrg } from '../../config/useSolutionOrg';
import { ReactComponent as PayPalLogo } from '../../assets/images/paypal_logo.svg';
import { ReactComponent as PendingBadge } from '../../assets/images/pending_badge.svg';
import { ReactComponent as CompletedBadge } from '../../assets/images/completed_badge.svg';
import { useFeatureFlags } from '../../config/FeatureFlagsProvider';
import ConnectWithPaypalCTA from '../../common/ConnectWithPaypalCTA';

const PaymentMethodStatus = {
  UNVERIFIED: 'unverified',
  PENDING: 'pending',
  VERIFIED: 'verified',
};

const cardStyle = (theme: Theme) => ({
  background: theme.palette.common.white,
  paddingLeft: theme.spacing(3.5),
  paddingRight: theme.spacing(3.5),
  paddingTop: theme.spacing(3),
  marginTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderRadius: theme.spacing(1.6),
  border: `${theme.spacing(0.125)} solid ${theme.palette.grey3.main}`,
} as const);

const buttonStyle = (theme: Theme) => ({
  position: 'static',
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  background: theme.palette.secondary.main,
  borderRadius: theme.spacing(3.75),
  fontWeight: '600',
  fontSize: theme.spacing(2.3),
  '&:hover': {
    backgroundColor: theme.palette.color1.main,
  },
  '&:disabled': {
    backgroundColor: theme.palette.color1.main,
    opacity: '0.4',
    color: theme.palette.common.white,
  },
  [theme.breakpoints.up('sm')]: {
    width: '100%',
    marginLeft: '0px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '60% !important',
    marginLeft: '0px',
  },
} as const);

const paypalButtonStyle = (theme: Theme) => ({
  position: 'static',
  // width: '80%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  background: theme.palette.paypalBtnColor.main,
  borderRadius: theme.spacing(3.75),
  // fontWeight: '500',
  // fontSize: theme.spacing(2.3),
  '&:hover': {
    backgroundColor: theme.palette.paypalBtnColor.dark,
  },
  '&:disabled': {
    backgroundColor: theme.palette.paypalBtnColor.main,
    opacity: '0.4',
    color: theme.palette.common.white,
  },
  [theme.breakpoints.up('sm')]: {
    width: '80%',
    marginLeft: '0px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '60% !important',
    marginLeft: '0px',
  },
} as const);

interface IFormInputs {
  email: string
  acknowledged: boolean
}

interface PaypalVerificationCardProps {
  optionalCardStyle: (theme: Theme) => {
    [key: string]: unknown;
  },
  optionalBtnStyles?: {
    [k: string]: unknown;
  };
  emailInputStyles?: {
    [k: string]: unknown;
  };
  editMode?: boolean;
}

interface PaypalVerificationCardHeaderProps {
  children: JSX.Element
}

export default function PaypalVerificationCard({
  optionalCardStyle,
  optionalBtnStyles,
  emailInputStyles,
  editMode,
}: PaypalVerificationCardProps) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const getPayPalAccountState = useAppSelector(selectGetPayPalAccountState);
  const setPayPalAccountIdState = useAppSelector(selectSetPayPalAccountIdState);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const dispatch = useAppDispatch();
  const { memberId, accessToken } = useAppSelector(selectMemberSession);
  const { company } = useAppSelector(selectMemberInformation);
  const payPalAccount = useAppSelector(selectPayPalAccount);
  const isDesktopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const theme = useTheme();
  const [isDirty, setIsDirty] = useState(false);
  const trackEvent = useTracking();
  const { IsInAppExperience, paypalConnectionReturnUrl } = useSolutionOrg();
  const [disableAcknowledge, setDisableAcknowledge] = useState(false);
  const [checkAcknowledge, setCheckAcknowledge] = useState(false);
  const [updateEmailTriggered, setUpdateEmailTriggered] = useState(false);
  const [paypalUnlinked, setPaypalUnlinked] = useState(false);
  const { connectWithPaypalFlag } = useFeatureFlags();
  const paypalAuthCode = searchParams.get('code');
  const [paypalLoginCTAClicked, setPaypalLoginCTAClicked] = React.useState(false);
  // TODO: use regex pattern in library module (Need to fix import issue of utils
  // library for this to work)

  useEffect(() => {
    if (accessToken && !(!editMode && payPalAccount?.status === PaymentMethodStatus.VERIFIED)) {
      dispatch(getPayPalAccount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // remove query params added by paypal from return url
  const removePaypalQueryParams = () => {
    const authCode = searchParams.get('code');
    if (authCode) {
      searchParams.delete('code');
      setSearchParams(searchParams);
    }
    const scope = searchParams.get('scope');
    if (scope) {
      searchParams.delete('scope');
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (connectWithPaypalFlag && paypalAuthCode) {
      const request: VerifyMemberPaypalIdentityInput = {
        authToken: paypalAuthCode,
      };
      dispatch(verifyAndUpdatePaypalEmail({
        memberId,
        data: request,
      }))
        .then(unwrapResult)
        .then((res) => {
          removePaypalQueryParams();
          setOpenErrorSnackbar(false);
        })
        .catch((error: unknown) => {
          setOpenErrorSnackbar(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paypalAuthCode]);

  const {
    handleSubmit,
    control,
    formState,
  } = useForm<IFormInputs>({ mode: 'onChange' });

  const onSubmit = handleSubmit((data: IFormInputs) => {
    if (data.email) {
      // no need to update if email has not changed and user's paypal is
      // already verified
      if (data.email === payPalAccount?.meta?.paypalAccountId &&
        payPalAccount?.status === PaymentMethodStatus.VERIFIED
      ) {
        setUpdateEmailTriggered(false);
        return;
      }

      const request: CreateOrUpdatePaymentMethodInput = {
        type: 'paypal',
        paypalAccountId: data.email,
        source: 'member',
        acknowledged: data.acknowledged,
      };

      dispatch(setPayPalAccountId(request))
        .then(unwrapResult)
        .then(() => {
          setOpenErrorSnackbar(false);
          setUpdateEmailTriggered(false);
        })
        .catch((error: unknown) => {
          setOpenErrorSnackbar(true);
        });
    }
  });

  const handleClose = () => {
    setOpenErrorSnackbar(false);
  };

  const openPaypal = () => {
    setPaypalLoginCTAClicked(true);
    const returnUrl = paypalConnectionReturnUrl;
    const appClientId = process.env.REACT_APP_PAYPAL_APP_ID;
    const baseUrl = process.env.REACT_APP_PAYPAL_CONNECTION_BASE_URL;
    const scopes = 'email';
    window.open(`${baseUrl}/?flowEntry=static&client_id=${appClientId}&response_type=code&scope=${scopes}&redirect_uri=${returnUrl}&fullPage=false`, '_parent');
  };

  const onResendEmail = async () => {
    setIsResending(true);
    try {
      await resendVerficationEmail(payPalAccount?.memberId, payPalAccount?.id);
    } catch (err) {
      console.log(err);
      trackEvent({
        optimusEventType: OnboardingEventType,
        optimusEvents: [{
          name: 'resend-verification-email-failed',
          detail: {
            error: err,
            memberId: payPalAccount?.memberId,
            paymethodMethodId: payPalAccount?.id,
          },
        }],
        solutionOrg: JoistSolutionOrgName,
      });
    }
    setIsResending(false);
  };

  useEffect(() => {
    console.log(!connectWithPaypalFlag);
    if (!connectWithPaypalFlag && payPalAccount?.acknowledgedAt) {
      setCheckAcknowledge(true);
      setDisableAcknowledge(true);
    } else {
      setCheckAcknowledge(false);
      setDisableAcknowledge(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payPalAccount?.acknowledgedAt]);

  const PaypalVerificationCardHeader = ({ children }: PaypalVerificationCardHeaderProps) => (
    <Box display={'flex'} sx={{ marginBottom: theme.spacing(3) }}>
      <Box display={'flex'} alignItems={'center'}>
        <PayPalLogo width={'48px'} height={'48px'} />
        <Typography variant='h4' component='div' sx={{
          marginBottom: theme.spacing(1.5),
          marginLeft: theme.spacing(2.5),
          marginRight: theme.spacing(2.5),
        }}>
          {t('verification.heading')}
        </Typography>
      </Box>
      {children}
    </Box>
  );

  return (
    <>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {t('error.generalMessage')}
        </Alert>
      </Snackbar>
      {getPayPalAccountState === 'pending' ? null : (!(payPalAccount?.meta?.paypalAccountId) ||
        payPalAccount?.status === PaymentMethodStatus.UNVERIFIED ||
        updateEmailTriggered || (connectWithPaypalFlag && payPalAccount?.status === PaymentMethodStatus.PENDING) ||
        (connectWithPaypalFlag && paypalUnlinked)) ? <Box
          sx={isDesktopScreen ? [cardStyle, optionalCardStyle] : [cardStyle]}
        >
        <PaypalVerificationCardHeader>
          <PendingBadge style={{ marginTop: '8px' }} />
        </PaypalVerificationCardHeader>
        {/* uses the default variant body1 */}
        <Typography
          sx={{
            color: theme.palette.color2.main,
            ...(editMode && { marginBottom: theme.spacing(3) }),
          }}>
          {
            <>
              {t('verification.description1')} {!connectWithPaypalFlag &&
                <>{t('verification.description1')}
                  <a target="_blank" rel="noopener noreferrer"
                    style={{ color: theme.palette.color2.main }}
                    href="https://www.paypal.com/business/getting-started">{t('verification.description2')}</a> {t('verification.description3')}
                </>
              }
            </>

          }
        </Typography>
        <form onSubmit={onSubmit}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <Controller
                name="acknowledged"
                control={control}
                rules={{ required: true }}
                render={({ field: props }) => (
                  <Checkbox
                    icon={<Box sx={{
                      border: '2px solid #CDD6DA',
                      width: theme.spacing(2.25),
                      height: theme.spacing(2.25),
                    }}></Box>}
                    sx={{
                      '&.MuiCheckbox-root': {
                        paddingTop: theme.spacing(0),
                        paddingLeft: theme.spacing(0),
                        width: theme.spacing(4.125),
                        height: theme.spacing(4.125),
                        '&.Mui-checked': {
                          color: theme.palette.secondary.main,
                        },
                      },
                    }}
                    {...props}
                    onChange={
                      (e) => {
                        props.onChange(e);
                        setCheckAcknowledge(e.target.checked);
                      }
                    }
                    checked={checkAcknowledge}
                    disabled={disableAcknowledge}
                  />
                )}
              />
            </Box>
            <Typography sx={{ color: theme.palette.color2.main }}>
              {t('verification.acknowledgemnet')}
            </Typography>
          </Box>
          <Grid container
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              pt: theme.spacing(1),
              // marginTop: theme.spacing(3),
              // marginBottom: theme.spacing(3),
            }}
          >
            {connectWithPaypalFlag ? <Grid item xs={12} sm={7} md={8} lg={7.5}
              sx={{
                [theme.breakpoints.between('sm', 'md')]: {
                  ml: theme.spacing(13),
                },
                [theme.breakpoints.down('sm')]: {
                  mt: theme.spacing(3),
                },
              }}
            >
              <ConnectWithPaypalCTA
                text={`${t('button.connectWithPaypal')}`}
                type={'submit'}
                optionalStackStyles={editMode ? {
                  display: 'flex',
                  justifyContent: 'center',
                } : undefined}
                sx={[paypalButtonStyle]}
                disable={!checkAcknowledge ||
                  !formState.isValid || setPayPalAccountIdState === 'pending'}
                onClick={openPaypal}
              />
            </Grid> : <> <Grid item xs={12} sm={7} md={8} lg={7.5}
              sx={{
                [theme.breakpoints.between('sm', 'md')]: {
                  ml: theme.spacing(13),
                },
                [theme.breakpoints.down('sm')]: {
                  mt: theme.spacing(3),
                },
              }}
            >
              <Controller
                name="email"
                control={control}
                defaultValue={payPalAccount?.meta?.paypalAccountId || ''}
                render={({
                  field: {
                    onChange, onBlur, value, ref,
                  },
                }) => <TextField
                    onChange={(event) => {
                      if (editMode) {
                        setIsDirty(!(_isEqual(
                          event.target.value,
                          payPalAccount?.meta?.paypalAccountId,
                        )));
                      }
                      onChange(event);
                      setCheckAcknowledge(false);
                      setDisableAcknowledge(false);
                    }}
                    placeholder={'Paypal email address'}
                    onBlur={onBlur}
                    value={value}
                    InputProps={{
                      sx: {
                        borderRadius: '104.8px',
                        border: '1px solid #CDD6DA',
                        background: '#FFFEFE',
                        fontWeight: '400',
                        fontSize: '16px',
                        paddingLeft: '17px',
                        ...(editMode && {
                          width: '100%',
                        }),
                      },
                    }}
                    sx={{
                      width: '100%',
                      ...(emailInputStyles && emailInputStyles),
                    }}
                  />}
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: emailRegex,
                    message: `${t('error.invalidEmail')}`,
                  },
                }}
              />
              {(formState?.errors?.email && formState.errors.email.type !== 'required') &&
                <Alert
                  sx={{
                    background: theme.palette.common.white,
                    color: theme.palette.error.main,
                    p: 0,
                    ...(editMode && {
                      '&.MuiAlert-root': {
                        marginBottom: theme.spacing(-5),
                      },
                    }),
                  }}
                  severity='error'>
                  {formState?.errors.email.message}
                </Alert>}
            </Grid>
              <Grid item xs={12} sm={12} md={3} lg={4}
                sx={{
                  ml: 'auto',
                  [theme.breakpoints.down('md')]: {
                    marginTop: theme.spacing(2),
                  },
                }}
              >
                <CallToActionButton
                  text={setPayPalAccountIdState === 'pending' ?
                    <CircularProgress color="inherit" size={24} /> : `${t('button.confirm')}`}
                  type={'submit'}
                  optionalStackStyles={editMode ? {
                    display: 'flex',
                    justifyContent: 'center',
                  } : undefined}
                  sx={[buttonStyle, ...(optionalBtnStyles ? [optionalBtnStyles] : [])]}
                  disable={!checkAcknowledge ||
                    !formState.isValid || setPayPalAccountIdState === 'pending'}
                />
              </Grid></>}
          </Grid>
        </form >
      </Box > : (payPalAccount?.status === PaymentMethodStatus.PENDING ? <Box
        sx={isDesktopScreen ? [cardStyle, optionalCardStyle] : [cardStyle]}
      >
        <PaypalVerificationCardHeader>
          <PendingBadge style={{ marginTop: '8px' }} />
        </PaypalVerificationCardHeader>
        <Box sx={{
          marginBottom: theme.spacing(2),
          color: theme.palette.color2.main,
        }}>
          <Typography component={'span'}>
            {t('verification.emailSentMessage')}
          </Typography>
          <Typography component='span' sx={{ fontWeight: 600 }}>
            {payPalAccount?.meta?.paypalAccountId || ''}
          </Typography>
          <Typography component={'span'}>
            {t('verification.emailSentMessage2')}
          </Typography>
        </Box>
        <Typography
          sx={{
            color: theme.palette.color2.main,
            marginBottom: theme.spacing(1),
          }}
        >
          {t('verification.emailSentMessage3')}
        </Typography>
        <Grid container
          sx={{
            alignItems: 'center',
            textAlign: 'center',
            pt: theme.spacing(1),
          }}
        >
          {connectWithPaypalFlag ? <Grid item xs={12} sm={7} md={8} lg={7.5}
            sx={{
              [theme.breakpoints.between('sm', 'md')]: {
                ml: theme.spacing(13),
              },
              [theme.breakpoints.down('sm')]: {
                mt: theme.spacing(3),
              },
            }}
          >
            <ConnectWithPaypalCTA
              text={paypalLoginCTAClicked ?
                <CircularProgress color="inherit" size={24} /> : `${t('button.connectWithPaypal')}`}
              showLoader={paypalLoginCTAClicked}
              type={'submit'}
              optionalStackStyles={editMode ? {
                display: 'flex',
                justifyContent: 'center',
              } : undefined}
              sx={[paypalButtonStyle]}
              disable={!checkAcknowledge ||
                !formState.isValid || setPayPalAccountIdState === 'pending'}
              onClick={openPaypal}
            />
          </Grid> : <>
            <Grid item xs={12} sm={7} md={8} lg={7.5}
              sx={{
                [theme.breakpoints.between('sm', 'md')]: {
                  ml: theme.spacing(13),
                },
                [theme.breakpoints.down('sm')]: {
                  mt: theme.spacing(3),
                },
              }}
            >
              <CallToActionButton
                text={isResending ?
                  <CircularProgress color="inherit" size={24} /> : t('verification.resendEmail') || ''}
                type={'submit'}
                optionalStackStyles={editMode ? {
                  display: 'flex',
                  justifyContent: 'center',
                } : undefined}
                onClick={onResendEmail}
                sx={[
                  buttonStyle,
                  ...(optionalBtnStyles ? [optionalBtnStyles] : []),
                  {
                    [theme.breakpoints.between('sm', 'md')]: {
                      width: '100% !important',
                      marginLeft: '0px',
                    },
                  },
                ]}
                disable={isResending}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={4}
              sx={{
                ml: 'auto',
                [theme.breakpoints.down('md')]: {
                  marginTop: theme.spacing(0),
                },
              }}
            >
              <CallToActionButton
                text={t('verification.editEmail') || ''}
                type={'submit'}
                optionalStackStyles={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                onClick={() => setUpdateEmailTriggered(true)}
                sx={[
                  buttonStyle,
                  ...(optionalBtnStyles ? [optionalBtnStyles] : []),
                  ...([{
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.secondary.main,
                    border: `1px solid ${theme.palette.secondary.main}`,
                    '&:hover': {
                      backgroundColor: theme.palette.common.white,
                    },
                  }]),
                ]}
                disableRipple
              />
            </Grid>
          </>}
        </Grid>
      </Box> : ((editMode && payPalAccount?.status === PaymentMethodStatus.VERIFIED) ?
        <Box
          sx={isDesktopScreen ? [cardStyle, optionalCardStyle] : [cardStyle]}
        >
          <PaypalVerificationCardHeader>
            <CompletedBadge style={{ marginTop: '8px' }} />
          </PaypalVerificationCardHeader>
          <Box sx={{
            marginBottom: theme.spacing(3),
            color: theme.palette.color2.main,
          }}>
            <Typography component='span'>
              {t('verification.completedMessage')}
            </Typography>
            <Typography component='span' sx={{ fontWeight: 600 }}>
              {payPalAccount?.meta?.paypalAccountId || ''}
            </Typography>
            <Typography component='span'>
              {t('verification.completedMessage2')}
            </Typography>
          </Box>
          <Divider sx={{ marginBottom: theme.spacing(3) }} />
          <Box
            sx={{
              mb: theme.spacing(3),
            }}>
            <Typography component='span' sx={{
              marginRight: theme.spacing(1),
            }}>
              {t('verification.unlinkMessage')}
            </Typography>
            <Typography component='span'>
              <Link
                component="button"
                variant="body2"
                color={theme.palette.secondary.main}
                onClick={() => (
                  connectWithPaypalFlag ? setPaypalUnlinked(true) : setUpdateEmailTriggered(true)
                )}
              >
                {t('verification.unlinkMessage2')}
              </Link>
            </Typography>
          </Box>
        </Box> : null))}
    </>
  );
}
