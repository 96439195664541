import { Box } from '@mui/system';
import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OnboardBusinessInformation, OnboardBusinessInformationProps } from './OnboardBusinessInformation';
import { useSolutionOrg } from '../../../config/useSolutionOrg';

export function OnboardBusinessInformationWrapper({ setIsOnboarding }:
  OnboardBusinessInformationProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { solutionOrgHasOnlyHomeDepot, isIframe } = useSolutionOrg();

  return (
    <Box>
      <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        <Typography component='div' variant='h12' sx={{ textAlign: 'center' }}>
          {t('onboarding.questionPlaceholder.tradeHeading1')}
        </Typography>
        <Typography component='div' sx={{
          textAlign: 'center',
          mt: theme.spacing(1.25),
          maxWidth: '70%',
        }}>
          {t('onboarding.questionPlaceholder.tradeHeading2')}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <OnboardBusinessInformation
          setIsOnboarding={setIsOnboarding}
          submitOnboardForm={!!(solutionOrgHasOnlyHomeDepot)}
          onlyHomeDepotIsPartner={solutionOrgHasOnlyHomeDepot as boolean}
          isIframe={isIframe}
        />
      </Box>
    </Box>
  );
}
