import {
  Autocomplete, Box, InputAdornment, TextField, Theme, useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { SxProps } from '@mui/system/styleFunctionSx';
import { useTranslation } from 'react-i18next';
import { useStylesForQuestionComponent } from '../../../common/QuestionComponent';
import { AutocompleteSx } from '../../../common/utils/AutocompleteSx';
import { PushSearchHistory, selectSearchHistory } from '../../../store/slices/contentSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ReactComponent as SearchIcon } from '../../../assets/images/search_icon.svg';

interface ContentSearchViewProps {
  sx?: SxProps<Theme>

  showBackBtn?: boolean;

  onBackBtnClick?: () => void;

  onSubmit?: (searchTerm?: string) => void;

  onClear?: () => void;

  value?: string
}

export default function ContentSearchView(props: ContentSearchViewProps) {
  const classes = useStylesForQuestionComponent();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(props.value || '');
  const dispatch = useAppDispatch();
  const history = useAppSelector(selectSearchHistory);
  const theme = useTheme();

  const sxFlex = {
    display: 'flex',
  };
  const sxFlexGrow = {
    flex: 1,
  };
  const { sx } = props;
  const sxSearchWrapper = {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.gradient.light,
    padding: theme.spacing(2),
  };

  useEffect(() => {
    dispatch(PushSearchHistory(searchValue));
    setSearchValue(props.value || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, dispatch]);

  // This will avoid onSubmit to be invoked during startup
  const setSearchValueProxy = (value: string) => {
    setSearchValue(value);
    if (props.onSubmit && value) {
      props.onSubmit(value);
    }
  };

  return (
    <Box sx={[
      sxFlex,
      sxSearchWrapper,
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}>
      <Autocomplete
        sx={[
          sxFlexGrow,
          AutocompleteSx.hideClearButton,
        ]}
        disablePortal
        classes={classes}
        options={history}
        openOnFocus={false}
        clearOnBlur={false}
        handleHomeEndKeys
        freeSolo
        value={searchValue}
        onChange={(event, newValue) => setSearchValueProxy(newValue ?? '')}
        renderInput={(params) => <TextField
          variant="outlined"
          placeholder={t('content.feed.searchForContent') || ''}
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />}
      />
    </Box>
  );
}
