import {
  Box, TextField, Theme, Skeleton,
} from '@mui/material';

const cardStyle = (theme: Theme) => ({
  background: theme.palette.common.white,
  borderRadius: theme.spacing(1.625),
  paddingLeft: theme.spacing(3.5),
  paddingRight: theme.spacing(3.5),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
  border: `1px solid ${theme.palette.grey3.main}`,
} as const);

export default function LennoxAccountNumberCardSkeleton() {
  return (
    <Box sx={[cardStyle]} >
      <Skeleton
        animation="wave"
        height={15}
        width="40%"
        style={{ marginBottom: 32 }}
      />
      <Skeleton
        animation="wave"
        height={15}
        width="100%"
        style={{ marginBottom: 6 }}
      />
      <Skeleton
        animation="wave"
        height={15}
        width="70%"
        style={{ marginBottom: 32 }}
      />
      <TextField
        type="text"
        disabled
        InputProps={{
          sx: {
            borderRadius: '104.8px',
            border: '1px solid #CDD6DA',
          },
        }}
        sx={{
          width: '100%',
        }}
      />
      <Skeleton
        animation="wave"
        height={55}
        variant="rectangular"
        sx={(theme) => ({
          marginTop: theme.spacing(2),
          borderRadius: theme.spacing(3),
        })}
      />
    </Box>
  );
}
