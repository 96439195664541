import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import AccountInformation from '../../common/AccountInformation';
import { OuterContainer } from './DashboardOuterContainerPagesLayout';

export default function AccountSettings() {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <OuterContainer
      sx={{
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        margin: '0 auto',
        width: '700px',
        padding: theme.spacing(0),
        [theme.breakpoints.up('xs')]: {
          maxWidth: '400px',
          width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
          maxWidth: '500px',
          width: '100%',
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: '600px',
          width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
          maxWidth: '700px',
          width: '100%',
        },
      }}
    >
      <>
        <Typography variant="h12">
            {t('navigation.settings')}
        </Typography>
        <AccountInformation />
      </>
    </OuterContainer>
  );
}
