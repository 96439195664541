/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendEventToOptimusBackend } from '../../api/onboardingAPI';
import {
  InvoiceSimpleSolutionOrgName,
  JoistSolutionOrgName,
  KickservSolutionOrgName,
  RoofsnapSolutionOrgName,
  ServiceFusionSolutionOrgName,
  SolutionOrgName,
} from '../../common/constants';
import { OptimusEvent } from '../slices/onboardingSlice';
import { RootState } from '../storeSetup';

export const sendOnboardingEventToOptimusBackendThunk = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  { optimusEvents: OptimusEvent[], solutionOrg: SolutionOrgName },
  // Types for ThunkAPI
  {
    state: RootState
  }>(
    'event/sendOnboardingEventToOptimusBackend',
    async ({ optimusEvents, solutionOrg }, { getState }) => {
      const { memberSession: { memberDeviceType, memberDeviceVersion } } = getState().onboarding;

      optimusEvents.forEach((optimusEvent) => {
        // add device type, device version and solution org name
        optimusEvent.detail = optimusEvent.detail || {};
        if (!(optimusEvent.detail?.memberDeviceType)) {
          optimusEvent.detail.memberDeviceType = memberDeviceType;
        }

        if (!(optimusEvent.detail?.memberDeviceVersion)) {
          optimusEvent.detail.memberDeviceVersion = memberDeviceVersion;
        }

        if (!(optimusEvent.detail?.solutionOrgName)) {
          optimusEvent.detail.solutionOrgName = solutionOrg;
        }
      });

      let eventPath = '';
      if (solutionOrg === JoistSolutionOrgName) {
        eventPath = 'joist-onboarding';
      } else if (solutionOrg === ServiceFusionSolutionOrgName) {
        eventPath = 'service-fusion-onboarding';
      } else if (
        solutionOrg === InvoiceSimpleSolutionOrgName ||
        solutionOrg === RoofsnapSolutionOrgName ||
        solutionOrg === KickservSolutionOrgName
      ) {
        eventPath = 'everpro-onboarding';
      }
      await sendEventToOptimusBackend(optimusEvents, eventPath);
    },
  );

export const sendContentEventToOptimusBackendThunk = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  OptimusEvent[],
  // Types for ThunkAPI
  {
    state: RootState
  }>(
    'event/sendContentEventToOptimusBackend',
    async (optimusEvents, { getState }) => {
      await sendEventToOptimusBackend(optimusEvents, 'content');
    },
  );

export const sendFeedEventToOptimusBackendThunk = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  OptimusEvent[],
  // Types for ThunkAPI
  {
    state: RootState
  }>(
    'event/sendContentEventToOptimusBackend',
    async (optimusEvents, { getState }) => {
      await sendEventToOptimusBackend(optimusEvents, 'feed');
    },
  );
export const sendSearchEventToOptimusBackendThunk = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  OptimusEvent[],
  // Types for ThunkAPI
  {
    state: RootState
  }>(
    'event/sendContentEventToOptimusBackend',
    async (optimusEvents, { getState }) => {
      await sendEventToOptimusBackend(optimusEvents, 'search');
    },
  );
