import {
  Alert,
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Dialog, DialogContent,
  Divider,
  Select,
  Snackbar,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  FormProvider, useForm,
}
  from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { SignUpMemberForHdSupplyInput } from '@optimus/models';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CallToActionButton from '../../../common/CallToActionButton';
import { ReactComponent as HDSupplyLogo } from '../../../assets/images/hdSupply_logo.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/images/refresh_icon.svg';
import { selectMemberInformation } from '../../../store/slices/onboardingSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useLogo } from '../../../config/useLogo';
import { selectMemberConfig, UpdateHDSupplyEnrollmentState } from '../../../store/slices/dashboardSlice';
import {
  signUpMemberForHdSupply,
} from '../../../api/dashboardAPI';
import { BusinessInformationFormControl, CompanyBusinessType } from './BusinessInformationFormControl';
import theme from '../../../assets/theme';
import { MemberInformationFormControl } from './MemberInformationFormControl';
import { BusinessConfigFormControl } from './BusinessConfigFormControl';
import { MemberPreferenceFormControl } from './MemberPreferenceFormControl';
import { HDSupplyEnrollmentStatus } from '../../../common/enums';
import { getHDSupplyMemberRelation, getMemberConfig } from '../../../store/thunks/dashboardThunk';
import { getMemberInformation } from '../../../store/thunks/onboardingThunk';
import { useTracking } from '../../../common/useTracking';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';

interface TypographyProps {
  children: string | JSX.Element;
}

type HDSupplySignupFormDialogProps = {
  confirmBtnText: string;
  cancelBtnText: string;
  title: string;
  description: string;
  disable?: boolean;
  onBtnClick?: () => void;
  updateOpenState: (event: boolean) => void;
};

type EditLinkProps = {
  updateEditState: (event: boolean) => void;
};

type ErrorProps = {
  errorMessage?: string;
};

export interface IFormInputs {
  principalInCharge: {
    isMemberPrincipalInCharge: string,
    name: string,
    title: string,
    email: string,
  },
  memberInfo: {
    firstName: string,
    lastName: string,
  },
  memberConfig: {
    preferredLanguage: string,
  },
  companyConfig: {
    interestInInventorySolution: boolean,
    interestInAdditionalSolutions: boolean,
  },
  businessInfo: {
    name: string,
    email: string,
    phone: string,
    sameBusinessAndBillingAddress: boolean,
    address: {
      street: string,
      city: string,
      state: string,
      country: string,
      zipCode: string,
    },
    billingAddress: {
      street: string,
      city: string,
      state: string,
      country: string,
      zipCode: string,
    },
    businessType: string,
  }
}

// eslint-disable-next-line no-shadow
export enum MemberPreferredLanguage {
  English = 'English',
  Español = 'Español',
}

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  paddingTop: theme.spacing(0.5),
  marginBottom: theme.spacing(2),
  '& fieldset': {
    borderRadius: theme.spacing(1.5),
    border: `${theme.spacing(0.125)} solid ${theme.palette.grey3.main}}`,
  },
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey3.main,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey3.main,
  },
})) as typeof TextField;

export const StyledSelect = styled(Select)(() => ({
  width: '100%',
  marginTop: theme.spacing(0.5),
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey3.main,
    borderRadius: theme.spacing(1.5),
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey3.main,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey3.main,
  },
  '&.Mui-focused': {
    borderColor: theme.palette.grey3.main,
  },
})) as unknown as typeof Select;

export const EditOption = ({ updateEditState }: EditLinkProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    }}
    onClick={() => updateEditState(true)}
  >
    <Typography variant='h4'
      sx={{
        color: theme.palette.secondary.main,
        mr: theme.spacing(0.5),
      }}
    >Edit
    </Typography>
    <EditIcon fontSize='inherit'
      style={{
        color: theme.palette.secondary.main,
      }}
    />
  </Box>
);

export const ErrorAlert = ({ errorMessage }: ErrorProps) => (
  <Alert
    icon={false}
    sx={{
      fontSize: theme.spacing(1.75),
      background: theme.palette.common.white,
      color: theme.palette.error.main,
      p: 0,
      '& .MuiAlert-message': {
        padding: `${theme.spacing(0)} ! important`,
      },
    }}
    severity="error"
  >
    {errorMessage || ' This field is required'}
  </Alert>
);

export const Body8VariantTypography = ({ children }: TypographyProps) => (
  <Typography variant='body8' >{children}</Typography>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasEmptyValues = (obj: any): boolean => {
  if (!obj || typeof obj !== 'object') return false;

  return Object.keys(obj).some((key) => {
    const value = obj[key];
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      return hasEmptyValues(value);
    }
    return value === '';
  });
};

const hasEmptyMemberInfoValues = (memberInfo: IFormInputs['memberInfo']): boolean => hasEmptyValues(memberInfo);
const hasEmptyBusinessInfoValues = (businessInfo: IFormInputs['businessInfo']): boolean => {
  const { businessType, ...businessInfoValidationForm } = businessInfo;
  return hasEmptyValues(businessInfoValidationForm);
};

export function HDSupplySignupFormDialog({
  updateOpenState,
}: HDSupplySignupFormDialogProps) {
  const [open, setOpen] = React.useState(true);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const { t } = useTranslation();
  const memberInfo = useAppSelector(selectMemberInformation);
  const { secondaryOne: SolutionOrgLogo } = useLogo();
  const dispatch = useAppDispatch();
  const storeMemberConfig = useAppSelector(selectMemberConfig);
  const [loading, setLoading] = useState(false);
  const trackEvent = useTracking();
  const trackOnboardEvent = useOnboardEventTracking();

  useEffect(() => {
    dispatch(getMemberInformation({ trackEvent }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!storeMemberConfig) {
      dispatch(getMemberConfig());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Sanitizes a string: trims, remove multiple spaces, limits to 255 characters (XSS)
  // Returns an empty string if undefined, null, or contains only blank spaces
  const sanitizeString = (input: string | undefined): string => {
    if (input === undefined || input === null || input.trim() === '') {
      return '';
    }
    return input.trim().replace(/\s+/g, ' ').substring(0, 255);
  };

  const validateField = (value: string | undefined, fieldName: string): void => {
    if (sanitizeString(value) === '') {
      throw new Error(`${fieldName} ${t('error.required')}`);
    }
  };

  const checkDataBeforeSend = (data: IFormInputs): void => {
    try {
      // Validate MemberInfo fields
      validateField(data.memberInfo.firstName, `${t('onboarding.questionPlaceholder.First Name')}`);
      validateField(data.memberInfo.lastName, `${t('onboarding.questionPlaceholder.Last Name')}`);

      // Validate PrincipalInCharge fields
      if (data.principalInCharge.isMemberPrincipalInCharge !== 'yes') {
        validateField(data.principalInCharge.name, `${t('hdSupply.businessConfig.principalIncharge.name')}`);
      }
      validateField(data.principalInCharge.title, `${t('hdSupply.businessConfig.principalIncharge.title')}`);
      validateField(data.principalInCharge.email, `${t('hdSupply.businessConfig.principalIncharge.email')}`);

      // Validate MemberConfig fields
      validateField(data.memberConfig.preferredLanguage, `${t('hdSupply.memberPreference.language')}`);

      // Validate BusinessInfo fields
      validateField(data.businessInfo.name, `${t('hdSupply.businessInformation.name')}`);
      validateField(data.businessInfo.email, `${t('hdSupply.businessInformation.email')}`);
      validateField(data.businessInfo.phone, `${t('hdSupply.businessInformation.phone')}`);

      // Validate Address fields
      validateField(data.businessInfo.address.street, `${t('hdSupply.businessInformation.address.street')}`);
      validateField(data.businessInfo.address.city, `${t('hdSupply.businessInformation.address.city')}`);
      validateField(data.businessInfo.address.state, `${t('hdSupply.businessInformation.address.state')}`);
      validateField(data.businessInfo.address.country, `${t('hdSupply.businessInformation.address.country')}`);
      validateField(data.businessInfo.address.zipCode, `${t('hdSupply.businessInformation.address.zipcode')}`);

      // Validate Billing Address fields (if applicable)
      if (!data.businessInfo.sameBusinessAndBillingAddress) {
        validateField(data.businessInfo.billingAddress.street, `${t('hdSupply.businessInformation.address.street')}`);
        validateField(data.businessInfo.billingAddress.city, `${t('hdSupply.businessInformation.address.city')}`);
        validateField(data.businessInfo.billingAddress.state, `${t('hdSupply.businessInformation.address.state')}`);
        validateField(data.businessInfo.billingAddress.country, `${t('hdSupply.businessInformation.address.country')}`);
        validateField(data.businessInfo.billingAddress.zipCode, `${t('hdSupply.businessInformation.address.zipcode')}`);
      }

      // Validate Business Type
      validateField(data.businessInfo.businessType, `${t('hdSupply.businessInformation.type')}`);
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      } else {
        throw new Error(`${t('error.generalMessage')}`);
      }
    }
  };

  const methods = useForm<IFormInputs>({
    defaultValues: {
      memberInfo: {
        firstName: sanitizeString(memberInfo.firstName),
        lastName: sanitizeString(memberInfo.lastName),
      },
      companyConfig: {
        interestInAdditionalSolutions: false,
        interestInInventorySolution: false,
      },
      businessInfo: {
        name: sanitizeString(memberInfo?.companyName),
        email: sanitizeString(memberInfo?.companyEmail),
        phone: sanitizeString(memberInfo?.companyPhoneNumber),
        sameBusinessAndBillingAddress: true,
        address: {
          street: sanitizeString(memberInfo?.companyAddressStreet),
          city: sanitizeString(memberInfo.companyAddressCity),
          state: sanitizeString(memberInfo.companyAddressState),
          country: sanitizeString(memberInfo.companyAddressCity),
          zipCode: sanitizeString(memberInfo.companyAddressZipCode),
        },
        businessType: '',
      },
    },
    mode: 'onChange',
  });
  const {
    getValues, handleSubmit,
  } = methods;

  const DialogHeader = () => (
    <>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              mb: theme.spacing(3),
              mt: theme.spacing(2),
            }}
          >
            <Box
              sx={{
                height: theme.spacing(10),
                width: theme.spacing(10),
                p: theme.spacing(1.5),
                border: '1px solid #DDD',
                borderRadius: theme.spacing(1),
              }}
            >
              <HDSupplyLogo width={theme.spacing(6.8)} height={theme.spacing(6.8)} />
            </Box>
            <Box
              sx={{
                ml: theme.spacing(2),
                mr: theme.spacing(2),
              }}
            >
              <RefreshIcon />
            </Box>
            <Box
              sx={{
                height: theme.spacing(10),
                width: theme.spacing(10),
                p: theme.spacing(0.5),
                border: '1px solid #DDD',
                borderRadius: theme.spacing(1),
              }}
            >
              {SolutionOrgLogo && <SolutionOrgLogo
                width={theme.spacing(8.75)} height={theme.spacing(8.75)} />}
            </Box>
          </Box>
          <Typography variant="h2" component="div" align="center">
            {t('hdSupply.signUpDialog.heading1')}
          </Typography>
          <Typography variant="body1" align="center" mt={theme.spacing(1)}>
            {t('hdSupply.signUpDialog.heading2')}
          </Typography>
        </CardContent>
      </Card>
      <Backdrop
        sx={{ color: theme.palette.common.white, zIndex: () => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );

  const handleClose = () => {
    setOpen(false);
    updateOpenState(false);
    setLoading(false);
  };

  const handleSnackbarClose = () => {
    setErrorMessage('');
    setSnackbarOpen(false);
  };

  const onSubmit = (data: IFormInputs) => {
    if (data) {
      try {
        checkDataBeforeSend(data);
        setLoading(true);

        // send event to indicate that form submission has been initiated
        trackOnboardEvent('hd-supply-sign-up-form-submission-initiated', {
          formData: data,
        });

        const updatedMemberInfoRequest = {
          firstName: getValues('memberInfo.firstName'),
          lastName: getValues('memberInfo.lastName'),
        };

        const updateMemberConfigRequest = {
          preferredLanguage: getValues('memberConfig.preferredLanguage') as MemberPreferredLanguage,
          configurationId: storeMemberConfig?.id as string,
        };

        const updateCompanyPartnerRelationRequest = {
          partnerId: process.env.REACT_APP_HD_SUPPLY_PARTNER_ID || '',
          partnerSpecificQuestions: {
            interestInInventorySolution: getValues('companyConfig.interestInInventorySolution'),
            interestInAdditionalSolutions: getValues('companyConfig.interestInAdditionalSolutions'),
          },
        };

        const isBillingAndBusinessAddressSame = getValues('businessInfo.sameBusinessAndBillingAddress');

        const updateCompanyRequest = {
          name: getValues('businessInfo.name'),
          email: getValues('businessInfo.email'),
          phoneNumber: getValues('businessInfo.phone'),
          street: getValues('businessInfo.address.street'),
          city: getValues('businessInfo.address.city'),
          state: getValues('businessInfo.address.state'),
          country: getValues('businessInfo.address.country'),
          zipCode: getValues('businessInfo.address.zipCode'),
          billingStreet: isBillingAndBusinessAddressSame ? getValues('businessInfo.address.street') :
            getValues('businessInfo.billingAddress.street'),
          billingCity: isBillingAndBusinessAddressSame ? getValues('businessInfo.address.city') :
            getValues('businessInfo.billingAddress.city'),
          billingState: isBillingAndBusinessAddressSame ? getValues('businessInfo.address.state') :
            getValues('businessInfo.billingAddress.state'),
          billingCountry: isBillingAndBusinessAddressSame ? getValues('businessInfo.address.country') :
            getValues('businessInfo.billingAddress.country'),
          billingZipCode: isBillingAndBusinessAddressSame ? getValues('businessInfo.address.zipCode') :
            getValues('businessInfo.billingAddress.zipCode'),
          principalName: getValues('principalInCharge.isMemberPrincipalInCharge') === 'yes' ?
            `${getValues('memberInfo.firstName')} ${getValues('memberInfo.lastName')}` : getValues('principalInCharge.name'),
          principalEmail: getValues('principalInCharge.email'),
          principalTitle: getValues('principalInCharge.title'),
          principalMemberId: getValues('principalInCharge.isMemberPrincipalInCharge') === 'yes' ? memberInfo?.id : null,
          businessType: getValues('businessInfo.businessType') as CompanyBusinessType,
        };

        const hdSupplySignUpRequest: SignUpMemberForHdSupplyInput = {
          memberInfoUpdate: updatedMemberInfoRequest,
          memberConfigUpdate: updateMemberConfigRequest,
          companyPartnerRelationUpdate: updateCompanyPartnerRelationRequest,
          companyInfoUpdate: updateCompanyRequest,
        };

        // sign up member for hd supply
        signUpMemberForHdSupply(hdSupplySignUpRequest, memberInfo.id)
          .then(() => {
            dispatch(getHDSupplyMemberRelation());
            handleClose();
            dispatch(UpdateHDSupplyEnrollmentState(HDSupplyEnrollmentStatus.Submitted));
            dispatch(getMemberInformation({ trackEvent }));

            // send event for the data that was submitted
            trackOnboardEvent('hd-supply-sign-up-form-submitted', {
              formData: data,
            });
          })
          .catch((error) => {
            if (error instanceof Error) {
              setErrorMessage(error.message);
            } else {
              setErrorMessage('An unexpected error occurred. Try again Later');
            }
            setSnackbarOpen(true);
          });
      } catch (error) {
        setErrorMessage('An unexpected error occurred. Try again Later');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const hasEmptyMemberInfoFields = hasEmptyMemberInfoValues(getValues('memberInfo'));
  const hasEmptyBusinessInfoFields = hasEmptyBusinessInfoValues(getValues('businessInfo'));

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="hdsupply-signup-form-dialog"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.secondary.main,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogHeader />
          <Divider sx={{
            mb: theme.spacing(3),
          }} />
          <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit)}>
              <MemberInformationFormControl hasEmptyFields={hasEmptyMemberInfoFields} />
              <BusinessConfigFormControl />
              <Divider sx={{
                mt: theme.spacing(2),
              }} />
              <BusinessInformationFormControl hasEmptyFields={hasEmptyBusinessInfoFields} />
              <Divider sx={{
                mt: theme.spacing(4),
                mb: theme.spacing(3),
              }} />
              <MemberPreferenceFormControl />
              <Box
                width={'95%'}
                sx={{
                  mt: theme.spacing(4),
                  mb: theme.spacing(3),
                }}
              >
                <CallToActionButton
                  text={`${t('button.completeHdWebSetup')}`}
                  type={'submit'}
                  sx={{
                    position: 'static',
                    width: '100% !important',
                    height: 'revert',
                    background: theme.palette.secondary.main,
                    borderRadius: theme.spacing(1.5),
                    fontSize: `${theme.spacing(2)} !important`,
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.main,
                    },
                    '&:disabled': {
                      backgroundColor: theme.palette.secondary.main,
                      opacity: '0.4',
                      color: theme.palette.common.white,
                    },
                    [theme.breakpoints.up('sm')]: {
                      marginLeft: '0px',
                    },
                  }}
                />
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog >
      {/* Snackbar for Error Display */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
