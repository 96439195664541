import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import {
  Controller, useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Body8VariantTypography, ErrorAlert, IFormInputs } from './HDSupplySignupFormDialog';

export function MemberPreferenceFormControl() {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isInterestedInInventorySolution, setIsInterestedInInventorySolution] = useState(false);
  const [isInterestedInAdditionalSolutions, setIsInterestedInAdditionalSolutions] = useState(false);
  const {
    register, control, formState: { errors },
  } = useFormContext<IFormInputs>();

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Preferences
      </Typography>
      <Box
        sx={{
          mt: theme.spacing(1),
        }}
      >
        <Body8VariantTypography>{`${t('hdSupply.memberPreference.language')}*` || ''}
        </Body8VariantTypography>
        <Box
          sx={{
            mt: theme.spacing(1),
            mb: theme.spacing(3),
          }}
        >
          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <Controller
              name="memberConfig.preferredLanguage"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  aria-label="account status"
                  row
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  {
                    [
                      { value: 'English', label: 'English' },
                      { value: 'Español', label: 'Español' },
                    ].map((option, indx) => (
                      <FormControlLabel
                        key={indx}
                        value={option.value}
                        control={
                          <Radio
                            sx={{
                              display: 'none', // Hide default radio button circle
                            }}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              flex: 1, // Makes each button take equal width
                              border: `1px solid ${theme.palette.grey3.main}`,
                              borderRadius: theme.spacing(1.5),
                              padding: theme.spacing(1.875),
                              textAlign: 'center',
                              borderColor: (value === option.value ?
                                theme.palette.secondary.dark :
                                theme.palette.grey3.main),
                              '&:hover': {
                                backgroundColor: theme.palette.secondary.light,
                                borderColor: theme.palette.secondary.dark,
                              },
                              cursor: 'pointer', // Cursor like a button
                              transition: 'border-color 0.3s',
                            }}
                          >
                            {option.label}
                          </Typography>
                        }
                        {...register('memberConfig.preferredLanguage', { required: true })}
                        sx={{
                          flex: 1,
                          mr: indx + 1 !== 3 ? theme.spacing(1.25) : 0,
                          ml: 0,
                        }}
                      />
                    ))
                  }
                </RadioGroup>
              )} />
          </FormControl>
          {(errors.memberConfig?.preferredLanguage) && <ErrorAlert />}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Controller
              name="companyConfig.interestInInventorySolution"
              control={control}
              render={({ field: props }) => (
                <Checkbox
                  icon={<Box sx={{
                    border: `2px solid ${theme.palette.color8.main}`,
                    width: theme.spacing(2.25),
                    height: theme.spacing(2.25),
                  }}></Box>}
                  sx={{
                    '&.MuiCheckbox-root': {
                      paddingLeft: theme.spacing(0),
                      width: theme.spacing(4.125),
                      height: theme.spacing(4.125),
                      '&.Mui-checked': {
                        color: theme.palette.secondary.main,
                      },
                    },
                  }}
                  {...props}
                  onChange={
                    (e) => {
                      props.onChange(e);
                      setIsInterestedInInventorySolution(e.target.checked);
                    }
                  }
                  checked={isInterestedInInventorySolution}
                />
              )}
            />
          </Box>
          <Typography variant='body8'>
            {t('hdSupply.memberPreference.interestInInventorySolutions')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Controller
              name="companyConfig.interestInAdditionalSolutions"
              control={control}
              render={({ field: props }) => (
                <Checkbox
                  icon={<Box sx={{
                    border: `2px solid ${theme.palette.color8.main}`,
                    width: theme.spacing(2.25),
                    height: theme.spacing(2.25),
                  }}></Box>}
                  sx={{
                    '&.MuiCheckbox-root': {
                      paddingTop: theme.spacing(0),
                      paddingLeft: theme.spacing(0),
                      width: theme.spacing(4.125),
                      height: theme.spacing(4.125),
                      '&.Mui-checked': {
                        color: theme.palette.secondary.main,
                      },
                    },
                  }}
                  {...props}
                  onChange={
                    (e) => {
                      props.onChange(e);
                      setIsInterestedInAdditionalSolutions(e.target.checked);
                    }
                  }
                  checked={isInterestedInAdditionalSolutions}
                />
              )}
            />
          </Box>
          <Typography variant='body8'>
            {t('hdSupply.memberPreference.interestInAdditionalSolutions')}
          </Typography>
        </Box>
      </Box></>
  );
}
