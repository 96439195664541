import {
  Box, Theme, Skeleton,
} from '@mui/material';

const cardStyle = (theme: Theme) => ({
  height: 150,
  background: theme.palette.common.white,
  borderRadius: theme.spacing(1.25),
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.grey3.main}`,
} as const);

export default function RewardEarnedSkeleton() {
  return (
    <Box sx={[cardStyle]} >
      <Skeleton
        animation="wave"
        height={30}
        width="30%"
      />
      <Skeleton
        animation="wave"
        height={60}
        width="40%"
      />
      <Skeleton
        animation="wave"
        height={30}
        width="30%"
      />
    </Box>
  );
}
