import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CallToActionButton from './CallToActionButton';

type DialogPopupProps = {
  confirmBtnText: string;
  cancelBtnText: string;
  title: string;
  description: string;
  disable?: boolean;
  onBtnClick?: () => void;
  updateOpenState: (event: boolean) => void;
};

export function DialogPopup({
  confirmBtnText,
  cancelBtnText,
  title,
  description,
  onBtnClick,
  updateOpenState,
}: DialogPopupProps) {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();

  const handleSubmit = () => {
    if (onBtnClick) {
      setOpen(false);
      updateOpenState(false);
      onBtnClick();
    }
  };

  const handleClose = () => {
    setOpen(false);
    updateOpenState(false);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Typography
          sx={{
            fontSize: theme.spacing(2.5),
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.secondary.main,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography variant='body1' color={theme.palette.common.black}>
          {description}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-evenly',
          width: '50%',
          ml: 'auto',
          mb: theme.spacing(2),
          mt: theme.spacing(2),
          [theme.breakpoints.down('sm')]: {
            width: '80%',
          },
          [theme.breakpoints.between('sm', 'md')]: {
            width: '60%',
          },
        }}
      >
        <Link
          component="button"
          variant="body2"
          color={theme.palette.secondary.main}
          onClick={handleClose}
          underline='none'
        >
          {cancelBtnText}
        </Link>
        <CallToActionButton
          text={confirmBtnText}
          type={'submit'}
          onBtnClick={handleSubmit}
          sx={{
            position: 'static',
            width: '100% !important',
            height: 'revert',
            background: theme.palette.secondary.main,
            borderRadius: theme.spacing(3.75),
            fontSize: `${theme.spacing(2)} !important`,
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:disabled': {
              backgroundColor: theme.palette.secondary.main,
              opacity: '0.4',
              color: theme.palette.common.white,
            },
            [theme.breakpoints.up('sm')]: {
              marginLeft: '0px',
            },
          }}
        />
      </DialogActions>
    </Dialog >
  );
}
