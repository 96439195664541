import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import ContentSearchView from '../Content/ContentSearchView';
import ContentHome from '../Content/ContentHome';
import { TopicsCard } from './TopicsCard';
import { UpdateSelectedTopicIndex } from '../../store/slices/contentSlice';
import { useAppDispatch } from '../../store/hooks';
import { HOME_ROUTE } from '../../routes/namedRoutes';

export function ContentSearch() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') ?? undefined;
  const businessGoalTag = searchParams.get('businessGoal') ?? undefined;
  const topicTag = searchParams.get('topic') ?? undefined;
  const [searchTerm, setSearchTerm] = useState(query);
  const [hasNoResults, setHasNoResults] = useState(false);
  const dispatch = useAppDispatch();

  // get search box input value
  const getValue = () => query || businessGoalTag || topicTag;

  const [searchInputValue, setSearchInputValue] = useState(getValue());

  const backToHome = () => {
    dispatch(UpdateSelectedTopicIndex(-1));
    navigate(HOME_ROUTE);
  };

  const handleSubmit = (value: string | undefined) => {
    const params = new URLSearchParams();
    params.set('query', value || '');
    const route = `/search?${params.toString()}`;
    navigate(route);
    setSearchTerm(value);
  };

  useEffect(() => {
    setSearchInputValue(getValue());
    setSearchTerm(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (<>

    <ContentSearchView
      showBackBtn={true}
      onBackBtnClick={backToHome}
      onSubmit={handleSubmit}
      value={searchInputValue}
    />
    {/* for mobile view if there is no search term, show the spotlight topics */}
    {(isMobile && !searchTerm && !businessGoalTag && !topicTag) &&
      <Box
        sx={{
          padding: '24px 0px 0px 16px',
        }}
      >
        <TopicsCard />
      </Box>
    }
    {(hasNoResults && (searchTerm || businessGoalTag || topicTag)) && <Box>
      <Box sx={{
        textAlign: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        color: theme.palette.color2.main,
      }}>
        <Typography variant='body5'>{t('content.feed.noResultMessage')}</Typography>
        <Typography variant='body5'>{t('content.feed.alternativeSearchMessage')}</Typography>
        <Typography variant='body5'>{t('content.feed.tryBelowMessage')}</Typography>
      </Box>
    </Box>}
    {(searchTerm || businessGoalTag || topicTag) &&
      <ContentHome
        searchTerm={searchTerm}
        topic={topicTag}
        businessGoal={businessGoalTag}
        fullWidthContainer={true}
        contentViewBaseUrl={'/search'}
        setHasNoResults={setHasNoResults}
        showSearchBar={false}
      />
    }
  </>);
}
