import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CustomerSupportForm from '../../common/CustomerSupportForm';
import { OuterContainer } from './DashboardOuterContainerPagesLayout';

export default function SupportContact() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <OuterContainer>
      <>
        <Typography
          variant='h12'
          sx={{ marginBottom: theme.spacing(3.625), paddingLeft: theme.spacing(3) }}
        >
          {t('navigation.contactUs')}
        </Typography>
        <CustomerSupportForm isDashboardSupportPage/>
      </>
    </OuterContainer>
  );
}
