import Box from '@mui/material/Box';
import { Theme, Container } from '@mui/material';
import Banner from './Banner';

export interface OnboardingDesktopLayoutProps {
  children?: JSX.Element
  splashScreenNav?: JSX.Element
  customBanner?: JSX.Element | null
}
export default function OnboardingDesktopLayout({
  children,
  splashScreenNav,
  customBanner,
}: OnboardingDesktopLayoutProps) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          postion: 'relative',
        }}
      >
        {/* {EllipseDesktop && <EllipseDesktop style={{
          position: 'absolute',
          top: -32,
          right: 0,
          width: '180px',
          height: '108px',
        }}/>} */}
        <Box
          sx={(theme: Theme) => ({
            flex: 1,
            marginTop: theme.spacing(6),
          })}
        >
          {customBanner || <Banner customHeight={'70vh'}/>}
          {splashScreenNav}
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Container maxWidth='sm'
            sx={(theme: Theme) => ({
              textAlign: 'center',
              marginTop: theme.spacing(13.5),
              marginLeft: theme.spacing(7.5),
              marginRight: theme.spacing(0),

            })}
          >
            {children}
          </Container>
        </Box>
      </Box>
    </>
  );
}
