import {
  Container, Theme, useMediaQuery, Grid, Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../config/FeatureFlagsProvider';
import RewardsBanner from '../../common/RewardsBanner';
import RewardsQuestions from '../../common/RewardsQuestions';
import { ReactComponent as DashboardImage } from '../../assets/images/dashboard_image5.svg';
import { ReactComponent as JoistRewardsMainLogo }
  from '../../assets/images/joist_rewards_main_logo.svg';
import { useCompanyEnrollmentInfo } from '../../common/useCompanyEnrollmentInfo';
import PaypalVerificationCard from '../Rewards/PaypalVerificationCard';

const optionalCardStyle = (theme: Theme) => ({
  marginTop: '24px',
  marginBottom: '0px',
  height: 'fit-content',
} as const);

const optionalContainerStyle = (theme: Theme) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
} as const);

const containerStyle = (theme: Theme) => ({
  marginTop: '20px',
} as const);

export function DashboardJoist() {
  const { t } = useTranslation();
  const isDesktopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  // const { contentFlag, verificationEmailFlag } = useFeatureFlags();
  const { verificationEmailFlag } = useFeatureFlags();

  // check company enrollment status
  useCompanyEnrollmentInfo({
    calledFromOnboardingComponent: false,
    redirectPath: '/in-app/onboarding',
  });

  return (
    <Container maxWidth={'sm'} sx={isDesktopScreen ? [
      containerStyle, optionalContainerStyle] : [containerStyle]}>
      <JoistRewardsMainLogo />
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 4, md: 4 }}
        sx={{ maxWidth: '718px', justifyContent: 'center' }}
      >
        <Grid item xs={4} sm={4} md={4}>
          <RewardsBanner
            logo={
              <Box sx={(theme: Theme) => ({
                [theme.breakpoints.up('lg')]: {
                  width: '80%',
                },
              })}>
                <DashboardImage />
              </Box>
            }
            mainTextBox={
              <Box sx={(theme: Theme) => ({
                fontWeight: '700',
                fontSize: '30.5433px',
                color: theme.palette.common.white,
                lineHeight: '37px',
                letterSpacing: '0.335977px',
                paddingRight: '20px',
                marginTop: '18px',
              })}>
                {t('dashboard.description1')}
              </Box>
            }
            upperTextBox={
              <Box sx={(theme: Theme) => ({
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '19px',
                letterSpacing: '0.0898333px',
                color: theme.palette.common.white,
                marginTop: '18px',
                paddingRight: '20px',
                [theme.breakpoints.up('lg')]: {
                  width: '70%',
                },
              })}>
                {t('dashboard.description2')}
              </Box>
            }
            lowerTextBox={
              <Box sx={(theme: Theme) => ({
                fontWeight: '400',
                fontSize: '10px',
                lineHeight: '12px',
                letterSpacing: '-0.287467px',
                color: theme.palette.common.white,
                marginTop: '8px',
                paddingRight: '20px',
                marginBottom: '24px',
                [theme.breakpoints.up('lg')]: {
                  width: '50%',
                  height: 'fit-content',
                },
              })}>
                &#42;{t('dashboard.description3')}
              </Box>
            }
          />
        </Grid>
        {verificationEmailFlag &&
          <Grid item xs={4} sm={4} md={4}>
            <PaypalVerificationCard optionalCardStyle={optionalCardStyle} />
          </Grid>}
        {/* contentFlag && <ContentHome contentViewBaseUrl={'/dashboard'} /> */}
        <Grid item xs={4} sm={4} md={4}>
          <RewardsQuestions />
        </Grid>
      </Grid>
    </Container>
  );
}
