import { Navigate, useOutlet } from 'react-router-dom';
import AuthenticatedRoute from '../AuthenticatedRoute';
import { DashboardLayout } from './DashboardLayout';
import { useCompanyEnrollmentInfo } from '../../common/useCompanyEnrollmentInfo';

export function DashboardHome() {
  const outlet = useOutlet();

  // check company enrollment status
  useCompanyEnrollmentInfo({
    calledFromOnboardingComponent: false,
    calledFromRewardsPage: window.location.pathname.includes('/rewards'),
  });

  return (
      <AuthenticatedRoute>
        <DashboardLayout>
          {outlet || <Navigate to="/home" />}
        </DashboardLayout>
      </AuthenticatedRoute>
  );
}
