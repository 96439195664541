import { useTranslation } from 'react-i18next';
import {
  Controller,
  useForm,
  FieldValues,
} from 'react-hook-form';
import {
  Alert,
  Box,
  CircularProgress,
  Snackbar,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import _isEqual from 'lodash/isEqual';
import {
  Question, QuestionOption,
} from './models/Question';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectCompanyEnrollmentInformation, selectMemberSession, selectUpdatePartnersOfMemberStatus } from '../store/slices/onboardingSlice';
import ImageOptionComponent from './ImageOptionComponent';
import { Partner } from './models/Partner';
import { updatePartnersOfMember } from '../store/thunks/onboardingThunk';
import CallToActionButton from './CallToActionButton';
import { getSelectedPartnersForMember } from '../store/thunks/dashboardThunk';
import LennoxAccountNumberCard from './LennoxAccountNumberCard';
import PartnerEnrollmentCard from './PartnerEnrollmentCard';
import { UpdateHDSetupFlowStepsCompletionState, resetHomeDepotMemberRelation } from '../store/slices/dashboardSlice';
import { HDSetupFlowSteps } from './enums';
import { HDSetupFlowCard } from '../components/Dashboard/HDOnboardingFlow/HDSetupFlowCard';
import { useFeatureFlags } from '../config/FeatureFlagsProvider';
import { useSolutionOrg } from '../config/useSolutionOrg';
import { isPriorCompanyEnrollment } from './utils/DateUtils';
import HDSupplyEnrollmentCard from '../components/Rewards/HDSupplySignupForm/HDSupplyEnrollmentCard';
import PaypalVerificationCard from '../components/Rewards/PaypalVerificationCard';

interface SuppliersUpdateCardProp {
  questions: Question[] | undefined
}

const optionalCardStyle = (theme: Theme) => ({
  width: '100%',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(0),
  height: 'fit-content',
  borderRadius: theme.spacing(1.6),
  border: `${theme.spacing(0.125)} solid ${theme.palette.grey3.main}`,
} as const);

export default function SuppliersUpdateCard({ questions }: SuppliersUpdateCardProp) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const { memberId } = useAppSelector(selectMemberSession);
  const updatePartnersOfMemberStatus = useAppSelector(selectUpdatePartnersOfMemberStatus);
  const companyEnrollmentInformation = useAppSelector(selectCompanyEnrollmentInformation);
  const [isDirty, setIsDirty] = useState(false);
  const dispatch = useAppDispatch();
  const { hdOnboardingForSNFlag, behrFlag, hdSupplyFlag } = useFeatureFlags();
  const { solutionOrgHasOnlyHomeDepot, hasDateBasedPartnerListCard } = useSolutionOrg();

  // Memoize the computation of showPartnerListCard
  const showPartnerListCard = useMemo(() => !solutionOrgHasOnlyHomeDepot || (
    hasDateBasedPartnerListCard && isPriorCompanyEnrollment(
      companyEnrollmentInformation?.createdDate,
    )
  ), [solutionOrgHasOnlyHomeDepot,
    hasDateBasedPartnerListCard,
    companyEnrollmentInformation?.createdDate]);

  const {
    handleSubmit,
    control,
  } = useForm({ mode: 'onChange' });

  const addSelectedPartners = (selectedPartners: Partner[]) => {
    dispatch(updatePartnersOfMember({ memberId, payload: selectedPartners }))
      .then(unwrapResult)
      .then(() => {
        setOpenSuccessSnackbar(true);
        setOpenErrorSnackbar(false);
        dispatch(getSelectedPartnersForMember());
        dispatch(UpdateHDSetupFlowStepsCompletionState(HDSetupFlowSteps.None));
        dispatch(resetHomeDepotMemberRelation());
      })
      .catch((error: unknown) => {
        setOpenSuccessSnackbar(false);
        setOpenErrorSnackbar(true);
      });
  };

  const onSubmit = (data: FieldValues) => {
    if (data) {
      const selectedPartners: Partner[] = [];
      Object.keys(data).forEach((key: string) => {
        const selectedValues = data[key].filter((item: QuestionOption) => item.isSelected);
        selectedValues.map((item: QuestionOption) => {
          // goes to partner object
          const partner: Partner = {
            partnerId: item.value,
          };
          return selectedPartners.push(partner);
        });
      });
      addSelectedPartners(selectedPartners);
    }
  };

  const handleClose = () => {
    setOpenErrorSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  return (
    <Box mb={4} sx={{ width: '100%', margin: 0, padding: 0 }}>
      {(questions?.length && showPartnerListCard) ?
        (<Box sx={{
          width: '100%',
          borderRadius: '13px',
          background: theme.palette.common.white,
          padding: theme.spacing(2.5),
          paddingLeft: theme.spacing(2.5),
          paddingRight: theme.spacing(2.5),
          [theme.breakpoints.down('sm')]: {
            marginTop: '16px',
          },
        }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Snackbar
              open={openSuccessSnackbar}
              autoHideDuration={4000}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Alert severity="success" sx={{ width: '100%' }}>
                {t('success.generalMessage')}
              </Alert>
            </Snackbar>
            <Snackbar
              open={openErrorSnackbar}
              autoHideDuration={4000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Alert severity="error" sx={{ width: '100%' }}>
                {t('error.generalMessage')}
              </Alert>
            </Snackbar>
            <Box>
              {questions?.map(
                (question: Question, index: number) => {
                  const controllerName = question.meta?.memberProperty as string ||
                    question.title + index;
                  return (
                    <Box key={question.id}>
                      <Box sx={{
                        mt: 2,
                        textAlign: 'left',
                        mb: 3,
                        color: theme.palette.color3.main,
                        [theme.breakpoints.down('lg')]: {
                          marginBottom: theme.spacing(3),
                        },
                      }}>
                        <Typography variant='h3'>{t('suppliers.heading')}</Typography>
                        <Typography color='rgba(60, 60, 67, 0.6);'>{t('suppliers.selectRetailers')}</Typography>
                      </Box>
                      <Controller
                        name={controllerName}
                        control={control}
                        rules={question.meta?.rules || {}}
                        defaultValue={question.options}
                        render={(
                          {
                            field: {
                              onChange,
                            },
                          },
                        ) => (
                          <div>
                            <ImageOptionComponent
                              options={question.options}
                              onChange={(value) => {
                                setIsDirty(!(_isEqual(value, question.options)));
                                onChange(value);
                              }}
                              optionalBoxStyles={{
                                [theme.breakpoints.up('lg')]: {
                                  marginLeft: '0px',
                                  width: '100% !important',
                                },
                              }}
                            />
                          </div>
                        )
                        }
                      />
                    </Box>
                  );
                },
              )}
            </Box>
            <CallToActionButton
              text={updatePartnersOfMemberStatus === 'pending' ?
                <CircularProgress color="inherit" size={24} /> : 'Done'}
              disable={!isDirty || !questions || updatePartnersOfMemberStatus === 'pending'}
              type='submit'
              optionalStackStyles={{
                display: 'flex',
                justifyContent: 'center',
              }}
              sx={{
                position: 'static',
                width: '100%',
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(2),
                [theme.breakpoints.up('sm')]: {
                  width: '60%',
                  marginLeft: '0px',
                },
                background: theme.palette.secondary.main,
                borderRadius: theme.spacing(3.75),
                '&:hover': {
                  backgroundColor: theme.palette.secondary.main,
                },
                '&:disabled': {
                  backgroundColor: theme.palette.secondary.main,
                },
              }}
            />
          </form>
          {/* } */}
        </Box>
        ) : null}
      <Box sx={{
        marginTop: (questions && questions.length > 0 && showPartnerListCard) ?
          '24px' : '-24px',
      }}>
        {hdOnboardingForSNFlag && <HDSetupFlowCard />}
        {behrFlag && hdOnboardingForSNFlag && <PartnerEnrollmentCard />}

        < LennoxAccountNumberCard
          optionalCardStyle={optionalCardStyle}
          optionalBtnStyles={{
            background: theme.palette.secondary.main,
            borderRadius: theme.spacing(3.75),
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:disabled': {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
          inputStyles={{
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: theme.palette.secondary.main,
              },
            },
          }}
          editMode
        />
        {hdSupplyFlag && <HDSupplyEnrollmentCard
          optionalCardStyle={optionalCardStyle}
          optionalBtnStyles={{
            background: theme.palette.secondary.main,
            borderRadius: theme.spacing(3.75),
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:disabled': {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
        />}
        <PaypalVerificationCard
          optionalCardStyle={optionalCardStyle}
          optionalBtnStyles={{
            background: theme.palette.secondary.main,
            borderRadius: theme.spacing(3.75),
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:disabled': {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
          emailInputStyles={{
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: theme.palette.secondary.main,
              },
            },
          }}
          editMode
        />
      </Box>
    </Box>
  );
}
