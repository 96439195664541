import {
  Alert,
  AlertTitle,
  CircularProgress,
  Skeleton,
  Snackbar,
  Theme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { FieldValues } from 'react-hook-form';
import { CreateOrUpdateMemberInput } from '@optimus/models';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  selectGetCompanyRoleOptionsStatus,
  selectGetMemberInformationStatus,
  selectGetOnboardingFormStatus,
  selectMemberSession,
} from '../store/slices/onboardingSlice';
import { getCompanyRoles, getMemberInformation, getOnboardingForm } from '../store/thunks/onboardingThunk';
import { useTracking } from './useTracking';
import AccountInformationSkeleton from './AccountInformationSkeleton';
import { OnboardingCompanyInformation } from '../components/Onboarding/CompanyInformation/OnboardingCompanyInformation';
import CallToActionButton from './CallToActionButton';
import * as onboardingAPI from '../api/onboardingAPI';
import { updateIsFormDirtyState } from './utils/formUtils';

export default function AccountInformation() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const trackEvent = useTracking();
  const { memberId } = useAppSelector(selectMemberSession);
  const getCompanyRoleOptionsStatus = useAppSelector(selectGetCompanyRoleOptionsStatus);
  const getMemberInformationStatus = useAppSelector(selectGetMemberInformationStatus);
  const getOnboardingFormStatus = useAppSelector(selectGetOnboardingFormStatus);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const isFetchingAccountInformation = getCompanyRoleOptionsStatus === 'pending' ||
   getMemberInformationStatus === 'pending' || getOnboardingFormStatus === 'pending';
  const [loading, setLoading] = useState(false);
  const [isDirtyMap, setIsDirtyMap] = useState<Map<string, boolean>>(new Map<string, boolean>());
  const [isDirty, setIsDirty] = useState(false);
  const [isFormStateValid, setIsFormStateValid] = useState(false);

  const getAccountInformationPageResources = () => {
    const getOnboardingFormResponse = dispatch(getOnboardingForm(
      {
        formId: process.env.REACT_APP_SF_FORM_ID || '',
        trackEvent,
      },
    ))
      .then(unwrapResult);

    const getMemberInformationResponse = dispatch(getMemberInformation(
      {
        id: memberId || undefined,
        trackEvent,
      },
    ))
      .then(unwrapResult);

    const getCompanyRolesResponse = dispatch(getCompanyRoles(
      {
        trackEvent,
      },
    ))
      .then(unwrapResult);

    Promise.all(
      [getOnboardingFormResponse, getMemberInformationResponse, getCompanyRolesResponse],
    )
      .then(() => {
        setOpenErrorSnackbar(false);
      })
      .catch(() => {
        setOpenErrorSnackbar(true);
      })
      .finally(() => {
        setIsDirtyMap(new Map<string, boolean>());
        setIsDirty(false);
      });
  };

  useEffect(() => {
    getAccountInformationPageResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpenErrorSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  const onSubmit = (data: FieldValues) => {
    if (data) {
      setLoading(true);
      onboardingAPI.updateMemberInformation(data as CreateOrUpdateMemberInput, memberId)
        .then(() => {
          setOpenSuccessSnackbar(true);
          setOpenErrorSnackbar(false);
          setLoading(false);
          getAccountInformationPageResources();
        })
        .catch((error: unknown) => {
          setOpenSuccessSnackbar(false);
          setOpenErrorSnackbar(true);
          setLoading(false);
        });
    }
  };

  const setIsFormDirty = (controllerName: string, isInputDirty: boolean) => {
    updateIsFormDirtyState(
      isDirtyMap,
      controllerName,
      isInputDirty,
      setIsDirtyMap,
      setIsDirty,
    );
  };

  return (
    <>
      <Snackbar
            sx={(theme: Theme) => ({
              [theme.breakpoints.up('md')]: {
                width: '80%',
                maxWidth: theme.spacing(126.5),
              },
            })}
            open={openSuccessSnackbar}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert severity="success" sx={{ width: '100%' }} onClose={handleClose}>
            <AlertTitle>{t('success.generalMessage2')}</AlertTitle>
            {t('success.account')}
            </Alert>
          </Snackbar>
          <Snackbar
            sx={(theme: Theme) => ({
              [theme.breakpoints.up('md')]: {
                width: '80%',
                maxWidth: theme.spacing(126.5),
              },
            })}
            open={openErrorSnackbar}
            autoHideDuration={10000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
          <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose}>
          <AlertTitle>{t('error.generalMessage2')}</AlertTitle>
          {t('error.account')}
          </Alert>
      </Snackbar>
      {isFetchingAccountInformation ?
      <>
          <AccountInformationSkeleton/>
          <Skeleton
            animation="wave"
            height={55}
            variant="rectangular"
            sx={(theme: Theme) => ({
              marginTop: theme.spacing(4),
              borderRadius: theme.spacing(3),
            })}
          />
      </> :
      <>
        <OnboardingCompanyInformation
          companyFormId='company-information-update-form'
          onSubmit={onSubmit}
          disableEmail={true}
          setIsDirty={setIsFormDirty}
          setIsFormStateValid={setIsFormStateValid}
          isFetchingAccountInformation={isFetchingAccountInformation}
        />
        <CallToActionButton
            text={ loading ?
            <CircularProgress color="inherit" size={24} /> : 'Update'}
            disable={!isDirty || !(isFormStateValid) || loading}
            form={'company-information-update-form'}
            type='submit'
            sx={(theme: Theme) => ({
              position: 'static',
              width: '100%',
              marginTop: theme.spacing(4),
              [theme.breakpoints.up('sm')]: {
                width: '100%',
                marginLeft: '0px',
              },
              background: theme.palette.secondary.main,
              borderRadius: '30px',
              '&:hover': {
                backgroundColor: theme.palette.secondary.main,
              },
              '&:disabled': {
                backgroundColor: theme.palette.secondary.main,
              },
            })}
          />
      </>
      }

    </>
  );
}
