import {
  Box, Container, useTheme,
} from '@mui/material';
import { DashboardNavElement } from './DashboardNavElement';
import { useLogo } from '../../config/useLogo';
import { DashboardScreensLayoutProps } from './DashboardLayout';

export interface DashboardSubMenuItemsProps {
  title: string;
  path: string;
  isCurrent?: boolean;
}

interface DashboardDesktopLayoutSFProps extends DashboardScreensLayoutProps {
  rightColumnElements?: JSX.Element[];
  fullWidth?: boolean;
}

const containerStyle = () => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '0px !important',
} as const);

export function DashboardDesktopLayout(
  {
    children, navElems, fullWidth, rightColumnElements = [],
  }: DashboardDesktopLayoutSFProps,
) {
  const theme = useTheme();
  const { secondaryOne: NavLogo } = useLogo();

  // leaving this for future as we dont need this for alignment now

  // const trackableRef = useRef<HTMLElement | null>(null);
  // useEffect(() => {
  //   const trackPosition = () => {
  //     if (trackableRef.current) {
  //       const rect = trackableRef.current.getBoundingClientRect();
  //       setpaddingLeft(`${rect.left}px`);
  //     }
  //   };

  //   // Track position on mount
  //   trackPosition();

  //   // Track position on resize
  //   window.addEventListener('resize', trackPosition);

  //   // Cleanup event listeners on unmount
  //   return () => {
  //     window.removeEventListener('resize', trackPosition);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={containerStyle}
      >
        <Box
          sx={{
            paddingLeft: theme.spacing(2.5),
            paddingRight: theme.spacing(2.5),
            paddingTop: theme.spacing(3.75),
            paddingBottom: theme.spacing(3.75),
            maxWidth: '22.5%',
            width: '22.5%',
            height: 'fit-content',
            position: 'sticky',
            top: theme.spacing(0),
          }}
        >
          {NavLogo && <NavLogo
            height={30}
            width={204}
            style={{
              marginBottom: theme.spacing(5),
              marginLeft: theme.spacing(0.5),
            }}
          />}
          {
            navElems.map((elem, index) => (
              <DashboardNavElement
                key={index}
                sx={{
                  marginBottom: theme.spacing(1.25),
                  padding: theme.spacing(1.875),
                }}
                name={elem.name}
                outlineIcon={elem.Outline}
                filledIcon={elem.Filled}
                path={elem.path}
                isSelected={elem.isCurrent ?? false}
                subMenuItems={elem.subMenuItems}
                bottomFixPosition={elem.bottomFixPosition}
                onClick={() => {
                  if (elem.onClick) elem.onClick();
                }}
              />
            ))
          }
        </Box>
        <Container sx={{
          paddingLeft: `${theme.spacing(0)} !important`,
          paddingRight: `${theme.spacing(0)} !important`,
          backgroundColor: theme.palette.common.white,
          display: 'flex',
          justifyContent: (rightColumnElements?.length > 0) ? 'auto' : 'center',
          borderRadius: `${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(0)} ${theme.spacing(0)}`,
          border: `1px solid ${theme.palette.grey3.main}`,
          maxWidth: 'none !important',
        }}
        >
          <Box sx={{
            padding: `${theme.spacing(5)} ${theme.spacing(2.5)}`,
            width: '100%',
            borderRight: (rightColumnElements?.length > 0) ? `1px solid ${theme.palette.grey3.main}` : theme.spacing(0),
            minHeight: '100vh',
          }}
          >
            <Box
              sx={{
                maxWidth: fullWidth ? theme.spacing(120) : 'none',
                width: fullWidth ? '100%' : theme.spacing(81.25),
                margin: 'auto',
              }}
            >
              {children}
            </Box>
          </Box>
          {(rightColumnElements?.length > 0) && <Box sx={{
            width: '100%',
            maxWidth: '35%',
            top: theme.spacing(12.5),
            pt: theme.spacing(5),
            pb: theme.spacing(5),
            pl: theme.spacing(2.5),
            pr: theme.spacing(2.5),
          }}>
            {
              rightColumnElements.map((element, index) => (
                <Box key={index} sx={{
                  marginBottom: theme.spacing(4),
                }}
                >
                  {element}
                </Box>))
            }
          </Box>}
        </Container>
      </Container>
    </>
  );
}
