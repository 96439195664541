const DEFAULT_SCRIPT_ID = 'reactjs-heap';

/**
 * Provides the Heap script to inject.
 */
const getHeapScript = (id: string | number) => `
  window.heapReadyCb=window.heapReadyCb||[],window.heap=window.heap||[],heap.load=function(e,t){window.heap.envId=e,window.heap.clientConfig=t=t||{},window.heap.clientConfig.shouldFetchServerConfig=!1;var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://cdn.us.heap-api.com/config/"+e+"/heap_config.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r);var n=["init","startTracking","stopTracking","track","resetIdentity","identify","getSessionId","getUserId","getIdentity","addUserProperties","addEventProperties","removeEventProperty","clearEventProperties","addAccountProperties","addAdapter","addTransformer","addTransformerFn","onReady","addPageviewProperties","removePageviewProperty","clearPageviewProperties","trackPageview"],i=function(e){return function(){var t=Array.prototype.slice.call(arguments,0);window.heapReadyCb.push({name:e,fn:function(){heap[e]&&heap[e].apply(heap,t)}})}};for(var p=0;p<n.length;p++)heap[n[p]]=i(n[p])};
  heap.load("${id}");
`;

/**
 * Get the script id from the environment.
 */
const getHeapScriptId = (): string | undefined => process.env.REACT_APP_HEAP_SCRIPT_ID;

type Heap = {
  identify: (memberId: string) => void;
  track: (eventName: string, eventProps?: {
    [key: string]: boolean | number | string,
  }) => void;
}

/**
 * Initializes Heap tracking.
 */
const initialize = () => {
  const scriptId = getHeapScriptId();
  if (!scriptId) {
    return;
  }

  if (!document) {
    return;
  }

  const hasScript = !!document.getElementById(DEFAULT_SCRIPT_ID);
  if (hasScript) {
    return;
  }

  const script = document.createElement('script');

  script.innerHTML = getHeapScript(scriptId);
  script.type = 'text/javascript';
  script.id = DEFAULT_SCRIPT_ID;
  script.async = true;

  document.body.appendChild(script);
};

/**
 * Object for manipulating Heap Analytics.
 */
const ReactHeap = {
  initialize,
};

export { ReactHeap };
export type { Heap };
