import {
  Box, Theme, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSolutionOrg } from '../config/useSolutionOrg';
import { generateDashboardQuestions } from '../assets/dashboardQuestions';
import { isPriorCompanyEnrollment } from './utils/DateUtils';
import { useAppSelector } from '../store/hooks';
import { selectCompanyEnrollmentInformation } from '../store/slices/onboardingSlice';

const optionalQuestionStyle = (theme: Theme) => ({
  width: '100%',
} as const);

const questionStyle = (theme: Theme) => ({
  marginTop: theme.spacing(1.375),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(1.375),
    width: '100%',
  },
  marginBottom: theme.spacing(2),
} as const);

const accordionBorder = (theme: Theme) => ({
  borderRadius: theme.spacing(1.625),
  border: `${theme.spacing(0.125)} solid ${theme.palette.grey3.main}`,
} as const);

interface RewardsQuestionsProps {
  noTitle?: boolean;
}

export default function RewardsQuestions({ noTitle }: RewardsQuestionsProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>(false);
  const isDesktopScreen = useMediaQuery(() => theme.breakpoints.up('lg'));
  const { solutionOrgSpecificAppName } = useSolutionOrg();
  const companyEnrollmentInformation = useAppSelector(selectCompanyEnrollmentInformation);
  const { solutionOrgHasOnlyHomeDepot, hasDateBasedPartnerListCard } = useSolutionOrg();
  // Memoize the computation of showing SF FAQs with all partners or with Home Depot only
  const showFAQsForAllPartners = useMemo(() => !solutionOrgHasOnlyHomeDepot || (
    hasDateBasedPartnerListCard && isPriorCompanyEnrollment(
      companyEnrollmentInformation?.createdDate,
    )
  ), [solutionOrgHasOnlyHomeDepot,
    hasDateBasedPartnerListCard,
    companyEnrollmentInformation?.createdDate]);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getFAQs = () => generateDashboardQuestions(
      solutionOrgSpecificAppName as string,
      showFAQsForAllPartners as boolean,
  );

  return (
    <>
      {!noTitle && <Box sx={{
        fontWeight: '400',
        fontSize: theme.spacing(2.5),
        lineHeight: theme.spacing(3.125),
        textAlign: 'center',
        letterSpacing: theme.spacing(0.0475),
        color: '#181818',
        marginTop: theme.spacing(2.875),
      }}>
        {t('onboarding.rewardsQuestion.description1')} <span style={{
          color: theme.palette.secondary.main,
        }}>{t('onboarding.rewardsQuestion.description2')}</span>
      </Box>}
      <Box sx={isDesktopScreen ? [questionStyle, optionalQuestionStyle] : [questionStyle]}>
        {getFAQs().map((question, index) => (
          <Accordion
            key={index}
            expanded={expanded === question.name} onChange={handleChange(question.name)}
            sx={{
              '&:before': {
                display: 'none',
              },
              marginBottom: theme.spacing(1),
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 0px rgba(0, 0, 0, 0.00)',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{
                color: theme.palette.secondary.main,
              }} />}
              sx={{
                ...accordionBorder(theme),
                '&.Mui-expanded': {
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
            >
              <Typography sx={{
                lineHeight: theme.spacing(3.125),
                letterSpacing: theme.spacing(0.0475),
              }}
                variant='h3'
                component={'div'}
              >
                {question.summary}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                ...accordionBorder(theme),
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              {/* uses default variant of body1 */}
              <Typography
                component={'div'}
              >
                {<question.Detail />}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  );
}
