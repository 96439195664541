/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box, Skeleton, Theme, Typography, useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const optionalQuestionStyle = (theme: Theme) => ({
  width: '100%',
} as const);

const questionStyle = (theme: Theme) => ({
  marginBottom: '16px',
} as const);

export default function AccountInformationSkeleton() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(() => theme.breakpoints.up('lg'));
  return (
    <>
      <Box>
        <Typography sx={{
          mb: 1, pt: 1, textAlign: 'left', color: theme.palette.color2.main,
        }}>
          {t('onboarding.account.heading')}
        </Typography>
        <Skeleton
          sx={() => ({
            borderRadius: '13px',
            marginBottom: 2,
            height: 132,
          })}
          animation="wave" variant="rectangular" />
        <Skeleton
          sx={() => ({
            borderRadius: '13px',
            marginBottom: 2,
            height: 50,
          })}
          animation="wave" variant="rectangular" />
        <Typography sx={{
          mb: 1, pt: 5, textAlign: 'left', color: theme.palette.color2.main,
        }}>
          {t('onboarding.company.heading')}
        </Typography>
        <Skeleton
          sx={() => ({
            borderRadius: '13px',
            marginBottom: 2,
            height: 264,
          })}
          animation="wave" variant="rectangular" />
      </Box>
    </>
  );
}
