import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Controller, useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { selectMemberInformation } from '../../../store/slices/onboardingSlice';
import { useAppSelector } from '../../../store/hooks';
import {
  Body8VariantTypography, ErrorAlert, IFormInputs, StyledTextField,
} from './HDSupplySignupFormDialog';
import { ValidFieldPattern } from '../../Onboarding/constants';

export function BusinessConfigFormControl() {
  const theme = useTheme();
  const { t } = useTranslation();
  const memberInfo = useAppSelector(selectMemberInformation);
  const [isMemberPrincipalInCharge, setIsMemberPrincipalInCharge] = useState('');
  const {
    register, setValue, control, formState: { errors },
  } = useFormContext<IFormInputs>();

  useEffect(() => {
    const newValue = (isMemberPrincipalInCharge === 'yes' ? memberInfo.email : '') || '';
    setValue('principalInCharge.email', newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMemberPrincipalInCharge]);

  return (
    <>  <Box
      sx={{
        mt: theme.spacing(2),
      }}
    >
      <Body8VariantTypography >{`${t('hdSupply.businessConfig.principalIncharge.question')}*` || ''}
      </Body8VariantTypography>
      <Box
        sx={{
          mt: theme.spacing(1),
          mb: theme.spacing(4),
        }}
      >
        <FormControl component="fieldset" sx={{ width: '100%' }}>
          <Controller
            name="principalInCharge.isMemberPrincipalInCharge"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                aria-label="account status"
                row
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setIsMemberPrincipalInCharge(e.target.value);
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {
                  [
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                  ].map((option, indx) => (
                    <FormControlLabel
                      key={indx}
                      value={option.value}
                      control={
                        <Radio
                          sx={{
                            display: 'none', // Hide default radio button circle
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            flex: 1, // Makes each button take equal width
                            border: `1px solid ${theme.palette.grey3.main}`,
                            borderRadius: theme.spacing(1.5),
                            padding: theme.spacing(1.875),
                            textAlign: 'center',
                            borderColor: (value === option.value ?
                              theme.palette.secondary.dark :
                              theme.palette.grey3.main),
                            '&:hover': {
                              backgroundColor: theme.palette.secondary.light,
                              borderColor: theme.palette.secondary.dark,
                            },
                            cursor: 'pointer', // Cursor like a button
                            transition: 'border-color 0.3s',
                          }}
                        >
                          {option.label}
                        </Typography>
                      }
                      {...register('principalInCharge.isMemberPrincipalInCharge', { required: true })}
                      sx={{
                        flex: 1,
                        mr: indx + 1 !== 3 ? theme.spacing(1.25) : 0,
                        ml: 0,
                      }}
                    />
                  ))
                }
              </RadioGroup>
            )} />
        </FormControl>
        {(errors.principalInCharge?.isMemberPrincipalInCharge) && <ErrorAlert />}
      </Box>
      <Box>
        {isMemberPrincipalInCharge === 'no' && <Box sx={{ mb: theme.spacing(2) }}>
          <Typography variant='body8'>{`${t('hdSupply.businessConfig.principalIncharge.name')}*`}</Typography>
          <StyledTextField
            placeholder='Name'
            {...register('principalInCharge.name', { required: true })}
          />
          {(errors.principalInCharge?.name) && <ErrorAlert />}
        </Box>}
        {isMemberPrincipalInCharge !== '' &&
          <>
            <Box sx={{ mb: theme.spacing(2) }}>
              <Body8VariantTypography>{`${t('hdSupply.businessConfig.principalIncharge.title')}*` || ''}</Body8VariantTypography>
              <StyledTextField
                placeholder='Title'
                {...register('principalInCharge.title', { required: true })}
              />
              {(errors.principalInCharge?.title) && <ErrorAlert />}
            </Box>
            <Box sx={{ mb: theme.spacing(2) }}>
              <Body8VariantTypography>{`${t('hdSupply.businessConfig.principalIncharge.email')}*` || ''}</Body8VariantTypography>
              <StyledTextField
                placeholder='Email'
                {...register('principalInCharge.email', {
                  required: true,
                  pattern: {
                    value: ValidFieldPattern.validEmailPattern,
                    message: `${t('error.invalidEmail2')}`,
                  },
                })}
              />
              {(errors.principalInCharge?.email) && <ErrorAlert
                errorMessage={errors.principalInCharge?.email?.message} />}
            </Box>
          </>
        }
      </Box>
    </Box></>
  );
}
