const DEFAULT_SCRIPT_ID = 'reactjs-customerIO';

interface CustomerIO {
  identify: (memberId: string) => void;
}

const getScript = (apiKey: string, siteId: string) => `
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdp.customer.io/v1/analytics-js/snippet/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._writeKey=key;analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.15.3";
    analytics.load("${apiKey}",
    { 
         "integrations": {
             "Customer.io In-App Plugin": { 
                 siteId: "${siteId}"
             }
         }
     });
    analytics.page();
  }}();
`;

export const ReactCustomerIO = {
  initialize() {
    const apiKey = process.env.REACT_APP_CUSTOMER_IO_API_KEY;
    const siteId = process.env.REACT_APP_CUSTOMER_IO_SITE_ID;
    if (!apiKey || !siteId) {
      return;
    }

    if (!document) {
      return;
    }

    const hasScript = !!document.getElementById(DEFAULT_SCRIPT_ID);
    if (hasScript) {
      return;
    }

    console.log('loading customer.io script');

    const script = document.createElement('script');

    script.innerHTML = getScript(apiKey, siteId);
    script.type = 'text/javascript';
    script.id = DEFAULT_SCRIPT_ID;
    script.async = true;

    document.body.appendChild(script);
  },
  identify(memberId: string) {
    const customerIO = (window as unknown as { analytics: CustomerIO })
      .analytics;
    if (!customerIO) {
      return;
    }
    try {
      console.log('Identifying user in customer.io', memberId);
      customerIO.identify(memberId);
    } catch (e) {
      console.error('Error identifying user in customer.io', e);
    }
  },
};
