import React from 'react';
import {
  Box, Typography, useTheme,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { REWARDS_ROUTE } from '../routes/namedRoutes';

interface BadgeProps {
  badgeType: string;
  badgeText: string;
  badgeSubtitle?: string | null;
  isButton?: boolean;
  redirectPath?: string;
  optionalStyles?: React.CSSProperties;
}

interface CheckedBadgeProps {
  badgeText: string;
  badgeIconColor: string;
}

interface EnrolledBadgeProps {
  badgeText: string;
  badgeSubtitle?: string | null;
}

interface NotEnrolledBadgeProps {
  badgeText: string;
  badgeSubtitle?: string | null;
  badgeIconColor?: string;
}

const CheckedBadge = ({ badgeText, badgeIconColor }: CheckedBadgeProps) => {
  const theme = useTheme();

  return (
    <>
      <CheckIcon
        sx={{
          fontSize: theme.spacing(2),
          color: badgeIconColor,
        }}
      />
      <Typography
        variant="body2"
        sx={{
          paddingRight: theme.spacing(0.25),
          fontSize: theme.spacing(1.75),
          [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(1.5),
          },
        }}
      >
        {badgeText}
      </Typography>
    </>
  );
};

const EnrolledBadge = ({ badgeText, badgeSubtitle }: EnrolledBadgeProps) => {
  const theme = useTheme();

  return (
    <>
      <Typography
        variant="h2"
        sx={{
          lineHeight: theme.spacing(3),
          [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(2),
          },
        }}
      >
        {badgeText}
      </Typography>
      {badgeSubtitle && (
        <Typography
          variant="h13"
          sx={{
            lineHeight: theme.spacing(2),
          }}
        >
          {badgeSubtitle}
        </Typography>
      )}
    </>
  );
};

const NotEnrolledBadge = ({
  badgeText, badgeSubtitle,
  badgeIconColor,
}: NotEnrolledBadgeProps) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            lineHeight: theme.spacing(3),
            mr: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.spacing(2),
            },
          }}
        >
          {badgeText}
        </Typography>
        <ArrowForwardIcon
          sx={{
            fontSize: theme.spacing(2.25),
            color: badgeIconColor,
            display: 'flex',
            alignSelf: 'center',
          }}
        />
      </Box>
      <Box>
        <Typography
          variant="h13"
          sx={{
            lineHeight: theme.spacing(2),
          }}
        >
          {badgeSubtitle}
        </Typography>
      </Box>
    </>
  );
};

export function Badge({
  badgeType,
  badgeText,
  badgeSubtitle,
  isButton = false,
  redirectPath,
  optionalStyles = {},
}: BadgeProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isCheckBadge = badgeType === 'checkmark';

  // Set default values based on theme or passed optionalStyles
  const customBackgroundColor = optionalStyles.backgroundColor || theme.palette.color23.main;
  const customBorderColor = optionalStyles.borderColor || theme.palette.color24.main;

  const handleClickNotEnrolled = () => {
    navigate(redirectPath || REWARDS_ROUTE);
  };

  const renderBadgeContent = () => {
    switch (badgeType) {
      case 'checkmark':
        return (
          <CheckedBadge
            badgeText={badgeText}
            badgeIconColor={customBorderColor}
          />
        );
      case 'enrolled':
        return (
          <EnrolledBadge
            badgeText={badgeText}
            badgeSubtitle={badgeSubtitle}
          />
        );
      case 'notEnrolled':
        return (
          <NotEnrolledBadge
            badgeText={badgeText}
            badgeSubtitle={badgeSubtitle}
            badgeIconColor={customBorderColor}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isCheckBadge ? 'row' : 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: customBackgroundColor,
        border: `${theme.spacing(0.125)} solid ${customBorderColor}`,
        borderRadius: isCheckBadge ? theme.spacing(12.5) : theme.spacing(1),
        padding: isCheckBadge ? `${theme.spacing(0.25)} ${theme.spacing(1.25)}` : theme.spacing(1.25),
        gap: isCheckBadge ? theme.spacing(0.2) : theme.spacing(0),
        width: '100%',
        textAlign: isCheckBadge ? 'left' : 'center',
        cursor: isButton ? 'pointer' : 'default',
        ...optionalStyles,
      }}
      onClick={isButton ? handleClickNotEnrolled : undefined}
    >
      {renderBadgeContent()}
    </Box>
  );
}
