import {
  Card, CardContent, Skeleton, ThemeProvider,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ContentTheme } from './ContentTheme';
import ContentHeroBanner from './ContentHeroBanner';
import ContentCardArticle from './ContentCardArticle';
import ContentCardPodCast from './ContentCardPodcast';
import ContentCardHeader from './ContentCardHeader';
import ContentFooterView from './ContentFooterView';
import { ContentLikeStatus, MetaItem } from '../../common/models/content/Content';
import { useAppDispatch } from '../../store/hooks';
import * as contentAPI from '../../api/contentAPI';
import { getContentFormAnswers } from '../../store/thunks/contentThunk';
import { Question } from '../../common/models/Question';
import ContentFormView, { ContentFormQuestionProps } from './ContentFormView';

interface ContentCardProps {
  categoryText?: string | null,
  categoryColor?: string

  banner?: {
    url: string | undefined;
    width: number | undefined;
    height: number | undefined;
  } | null,
  logo?: string | null,
  contentText: string,
  contentBody?: string | null,
  contentShortDescription: string,

  authorTitle?: string | null,
  authorText?: string | null,
  isLiked?: boolean,
  isBookmarked?: boolean,
  publishedDate?: string,
  contentHeroListener?: () => void,
  videoUrl?: string | null
  contentId: number
  contentLikeStatus?: ContentLikeStatus
  formId: string | null
  metadata?: MetaItem[]
}

export default function ContentCard(props: ContentCardProps) {
  const [ContentHeroComponent, setContentHeroComponent] = useState<React.FC | null>(null);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  useEffect(() => {
    if (props.categoryText?.toUpperCase() === 'POLL' && props.formId) {
      const getContentFormResponse = contentAPI.getContentForm(props.formId);
      const getContentFormAnswersResponse =
        dispatch(getContentFormAnswers(props.formId));
      Promise.all([getContentFormResponse, getContentFormAnswersResponse])
        .then(([contentFormResponse]) => {
          let formQuestion: ContentFormQuestionProps;
          if (contentFormResponse?.questions && contentFormResponse?.questions?.length > 0) {
            // considering poll will have only one question
            const questionItem: Question = contentFormResponse?.questions[0];
            formQuestion = {
              questionId: questionItem.id,
              questionType: questionItem.type,
              title: questionItem.title,
              totalVotes: questionItem.totalVotes,
              options: questionItem.options ? questionItem.options : [],
            };
          }
          setContentHeroComponent(() => (
            () => <ContentFormView
              formId={contentFormResponse.id}
              question={formQuestion}
              contentId={props.contentId}
              contentCreatorName={props.authorTitle}
            />
          ));
        });
    } else if (props.categoryText?.toUpperCase() === 'ARTICLE') {
      setContentHeroComponent(() => (
        () => <ContentCardArticle
          OnclickListener={props.contentHeroListener}
          logo={props.logo}
          banner={props.banner}
          title={props.categoryText}
          heading={props.contentText}
          shortDescription={props.contentShortDescription}
          embedVideoUrl={props.videoUrl}
          contentId={props.contentId}
          contentCreatorName={props.authorTitle}
        />
      ));
    } else if (props.categoryText?.toUpperCase() === 'PODCAST') {
      setContentHeroComponent(() => (
        () => <ContentCardPodCast
          OnclickListener={props.contentHeroListener}
          banner={props.banner}
          title={props.categoryText}
          heading={props.contentText}
          shortDescription={props.contentShortDescription}
          embedVideoUrl={props.videoUrl}
          contentId={props.contentId}
          contentCreatorName={props.authorTitle}
          logo={props.logo}
          contentTitle={props.contentText}
        />
      ));
    } else {
      setContentHeroComponent(() => (
        () => <ContentHeroBanner
          OnclickListener={props.contentHeroListener}
          banner={props.banner}
          title={props.categoryText}
          heading={props.contentText}
          shortDescription={props.contentShortDescription}
          embedVideoUrl={props.videoUrl}
          contentId={props.contentId}
          contentCreatorName={props.authorTitle}
          logo={props.logo}
        />
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={ContentTheme}>
      <Card sx={{
        minWidth: 275,
        width: '100%',
        borderRadius: '10px',
        boxShadow: 0,
        padding: '14px',
      }}>
        <div>
          <CardContent sx={{
            paddingTop: '0px',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingBottom: '16px',
            [ContentTheme.breakpoints.down('sm')]: {
              paddingBottom: '10px',
            },

          }}>
            <ContentCardHeader
              heading={props.authorTitle}
              contentHeading={props.contentText}
              title={props.authorText}
              publishedDate={props.publishedDate}
              categoryButtonText={props.categoryText}
              categoryButtonColor={props.categoryColor}
              logo={props.logo}
              metadata={props.metadata} />
          </CardContent>
          {ContentHeroComponent ? <ContentHeroComponent /> : <Skeleton
            sx={() => ({
              borderRadius: '10px',
              [theme.breakpoints.down('sm')]: {
                height: 180,
              },
              height: 250,
            })}
            animation="wave" variant="rectangular" />}
          <ContentFooterView
            contentId={props.contentId}
            contentLikeStatus={props.contentLikeStatus}
            contentTitle={props.contentText}
            contentCreatorName={props.authorTitle}
            logo={props.logo}
            OnclickListener={props.contentHeroListener}
          />
        </div>
      </Card>
    </ThemeProvider>);
}
