import { Box } from '@mui/system';
import {
  Grid,
  Skeleton,
  useTheme,
} from '@mui/material';
import LennoxAccountNumberCardSkeleton from './LennoxAccountNumberCardSkeleton';

export default function SuppliersUpdateCardSkeleton() {
  const numberOfSuppliers = 7;
  const theme = useTheme();

  return (
    <Box>
      <Box sx={{
        borderRadius: '13px',
        background: '#FFFFFF',
        padding: theme.spacing(2.5),
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
        border: `1px solid ${theme.palette.grey3.main}`,
        [theme.breakpoints.down('sm')]: {
          marginTop: '16px',
        },
      }}>
        <Box sx={{
          mt: 2,
          textAlign: 'left',
          mb: 5,
          color: theme.palette.color3.main,
        }}>
          <Skeleton
            animation='wave'
            height={15}
            width='50%'
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation='wave'
            height={15}
            width='80%'
          />
        </Box>
        <Grid container justifyContent='center' mb='24px' spacing={{
          xs: 1, sm: 1, md: 1, lg: 2,
        }} columns={{
          xs: 12, sm: 12, md: 12, lg: 12,
        }}>
          {[...Array(numberOfSuppliers).keys()].map((item, index) => (
            <Grid item xs={5} sm={4} md={4} lg={3} key={index}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 2,
              }}>
                <Skeleton animation='wave' variant='circular'
                  sx={() => ({
                    mb: 1,
                    width: 110,
                    height: 110,
                  })}
                />
                <Skeleton
                  animation='wave'
                  height={10}
                  width='40%'
                />
              </Box>
            </Grid >
          ))
          }
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Skeleton
            animation='wave'
            height={55}
            variant='rectangular'
            sx={{
              width: '100%',
              borderRadius: '30px',
              [theme.breakpoints.up('sm')]: {
                width: '60%',
              },
            }}
          />
        </Box>
      </Box >
      <LennoxAccountNumberCardSkeleton />
    </Box >
  );
}
