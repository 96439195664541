import {
  Theme, useMediaQuery, Grid, Box, useTheme, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import SuppliersUpdateCard from '../../common/SuppliersUpdateCard';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectGetCompanyEnrollmentInformationStatus, selectPartnersInformationQuestions } from '../../store/slices/onboardingSlice';
import { getOnboardingForm } from '../../store/thunks/onboardingThunk';
import { getSelectedPartnersForMember, getYearlyPayments } from '../../store/thunks/dashboardThunk';
import { selectGetSelectedPartnersForMemberState, selectSelectedPartners, selectGetYearlyPaymentsState } from '../../store/slices/dashboardSlice';
import { useTracking } from '../../common/useTracking';
import SuppliersUpdateCardSkeleton from '../../common/SuppliersUpdateCardSkeleton';
import RewardEarnedSkeleton from '../../common/RewardEarnedSkeleton';
import { useSolutionOrg } from '../../config/useSolutionOrg';
import RewardEarned from './RewardEarned';
import { useFeatureFlags } from '../../config/FeatureFlagsProvider';
import { OuterContainer } from './DashboardOuterContainerPagesLayout';

const optionalContainerStyle = (theme: Theme) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
} as const);

export default function Rewards() {
  const { t } = useTranslation();
  const isMobile = !useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isDesktopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [showRewardHistory, setShowRewardHistory] = useState(false);
  const partnersInformationQuestions = useAppSelector(selectPartnersInformationQuestions);
  const selectedPartners =
    useAppSelector(selectSelectedPartners).map((relation) => relation.partnerId);
  const getSelectedPartnersForMemberState = useAppSelector(selectGetSelectedPartnersForMemberState);
  const getCompanyEnrollmentInformationStatus =
  useAppSelector(selectGetCompanyEnrollmentInformationStatus);
  const getYearlyPaymentsState = useAppSelector(selectGetYearlyPaymentsState);
  const yearlyPayments = useAppSelector((state) => state.dashboard.yearlyPayments);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const trackEvent = useTracking();
  const { envSolutionOrgFormId } = useSolutionOrg();
  const { hdOnboardingForSNFlag } = useFeatureFlags();
  useEffect(() => {
    dispatch(getOnboardingForm({
      formId: (envSolutionOrgFormId as string) || '',
      trackEvent,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getSelectedPartnersForMember());
    dispatch(getYearlyPayments());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RewardEarnedWithSkeleton = () => {
    if (getYearlyPaymentsState === 'pending') {
      return <RewardEarnedSkeleton />;
    }
    return (
      <RewardEarned
        isMobile={isMobile}
        showRewardHistory={showRewardHistory}
        setShowRewardHistory={setShowRewardHistory}
        rewards={yearlyPayments}
      />
    );
  };

  return (
    <Box sx={isDesktopScreen ? [optionalContainerStyle] : []}>
      <OuterContainer sx={{ padding: theme.spacing(2) }}>
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 4, sm: 12, md: 12 }}
        >
          <Typography
            variant="h12"
            sx={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(2),
              paddingLeft: theme.spacing(4),
            }}
          >
            {t('navigation.rewards')}
          </Typography>
          {
            isMobile && (
              <Grid item xs={4} sm={12} md={12}>
                {RewardEarnedWithSkeleton()}
              </Grid>
            )
          }
          <Grid container item xs={12} sm={12} md={12}>
            {
              !isMobile && (
                <Grid item xs={4} sm={12} md={12} sx={{ marginBottom: theme.spacing(2.5) }}>
                  {RewardEarnedWithSkeleton()}
                </Grid>
              )
            }
            {(getSelectedPartnersForMemberState === 'pending' ||
            getCompanyEnrollmentInformationStatus === 'pending' ?
              <SuppliersUpdateCardSkeleton /> :
              <SuppliersUpdateCard questions={
                partnersInformationQuestions?.map((question) => {
                  const clonedQuestion = cloneDeep(question);
                  // eslint-disable-next-line no-param-reassign
                  clonedQuestion.options = clonedQuestion.options?.map((option) => {
                    const newOption = {
                      ...option,
                      isSelected: selectedPartners?.includes(option.value),
                    };
                    return newOption;
                  });
                  // remove Home Depot partner if hdOnboardingForSNFlag is not on
                  if (!hdOnboardingForSNFlag) {
                    clonedQuestion.options =
                      clonedQuestion?.options?.filter((item) => item.value !==
                        process.env.REACT_APP_HOME_DEPOT_PARTNER_ID);
                  }
                  return clonedQuestion;
                })
              } />
            )}
          </Grid>
        </Grid>
      </OuterContainer>
    </Box>
  );
}
