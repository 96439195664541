import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import * as onboardingAPI from '../../api/onboardingAPI';
import * as contentAPI from '../../api/contentAPI';
import { OnboardingEventType } from '../../common/constants';
import { EnrollmentRequest, filterNullFields } from '../../common/models/EnrollmentRequest';
import { CompanyEnrollment, EnrollmentResponse } from '../../common/models/EnrollmentResponse';
import { Member } from '../../common/models/Member';
import { OnboardingForm } from '../../common/models/OnboardingForm';
import { UpdatePartnersOfMemberRequest } from '../../common/models/UpdatePartnersOfMemberRequest';
import { UpdatePartnersOfMemberResponse } from '../../common/models/UpdatePartnersOfMemberResponse';
import { getSolutionOrgName } from '../../common/utils/loginUtils';
import { TrackedEvent } from '../../common/useTracking';
import { RootState } from '../storeSetup';
import { CompanyRoleOptions } from '../../common/models/Question';
import { FormAnswer } from '../../common/models/FormAnswer';

export interface ResponseError {
  // TODO: improve this interface to account for expected errors from API call
  errorMessage?: string
  apiResponseErrorStatus?: number,
}

export const getOnboardingForm = createAsyncThunk<
  // Return type of the payload creator
  OnboardingForm,
  // First argument to the payload creator
  {
    formId: string,
    trackEvent:(
      event: TrackedEvent) => void
      },
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
      'onboarding/getOnboardingForm',
      async ({ formId, trackEvent }, { rejectWithValue, getState }) => {
        const { memberSession: { memberId, rewardProgramId, solutionOrgId } } =
        getState().onboarding;
        try {
          const response = await onboardingAPI.getOnboardingForm(formId);
          return response as OnboardingForm;
        } catch (err) {
          console.log(err);
          trackEvent({
            optimusEventType: OnboardingEventType,
            optimusEvents: [{
              name: 'get-onboarding-form-failed',
              detail: {
                error: err,
                memberId,
                rewardProgramId,
              },
            }],
            solutionOrg: getSolutionOrgName(solutionOrgId),
            apiResponseErrorStatus: (err as AxiosError)?.response?.status,
          });
          const responseError: ResponseError = { errorMessage: 'failed to get onboarding questions' };
          return rejectWithValue(responseError as ResponseError);
        }
      },
      );

export const getOnboardingFormAnswers = createAsyncThunk<
  // Return type of the payload creator
  FormAnswer[],
  // First argument to the payload creator
  {
    formId: string,
    trackEvent:(
      event: TrackedEvent) => void
      },
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
      'onboarding/getOnboardingFormAnswers',
      async ({ formId, trackEvent }, { rejectWithValue, getState }) => {
        const { memberSession: { memberId, rewardProgramId, solutionOrgId } } =
        getState().onboarding;
        try {
          const response = await contentAPI.getFormAnswers(formId, memberId);
          return response as FormAnswer[];
        } catch (err) {
          console.log(err);
          trackEvent({
            optimusEventType: OnboardingEventType,
            optimusEvents: [{
              name: 'get-onboarding-form-answers-failed',
              detail: {
                error: err,
                memberId,
                rewardProgramId,
              },
            }],
            solutionOrg: getSolutionOrgName(solutionOrgId),
            apiResponseErrorStatus: (err as AxiosError)?.response?.status,
          });
          const responseError: ResponseError = { errorMessage: 'failed to get onboarding answers' };
          return rejectWithValue(responseError as ResponseError);
        }
      },
      );

export const getMemberInformation = createAsyncThunk<
  // Return type of the payload creator
  Member,
  // First argument to the payload creator
  {
    id?: string,
    trackEvent:(
      event: TrackedEvent) => void
      },
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
      'onboarding/getMemberInformation',
      async ({ id, trackEvent }, { rejectWithValue, getState }) => {
        const { memberSession } = getState().onboarding;
        const memberId = memberSession.memberId || id;
        try {
          const response = await onboardingAPI.getMemberInformation(memberId);
          return response as Member;
        } catch (err) {
          console.log(err);
          trackEvent({
            optimusEventType: OnboardingEventType,
            optimusEvents: [{
              name: 'get-member-information-failed',
              detail: {
                error: err,
                memberId,
                rewardProgramId: memberSession.rewardProgramId,
              },
            }],
            solutionOrg: getSolutionOrgName(memberSession.solutionOrgId),
            apiResponseErrorStatus: (err as AxiosError)?.response?.status,
          });
          const responseError: ResponseError = { errorMessage: 'failed to get member information' };
          return rejectWithValue(responseError as ResponseError);
        }
      },
      );

export const getCompanyRoles = createAsyncThunk<
  CompanyRoleOptions[],
  {
    trackEvent:(
      event: TrackedEvent) => void
      },
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
      'onboarding/getCompanyRoles',
      async ({ trackEvent }, { rejectWithValue, getState }) => {
        const { memberSession: { solutionOrgId } } = getState().onboarding;
        try {
          const response = await onboardingAPI.getCompanyRoles();
          return response as CompanyRoleOptions[];
        } catch (err) {
          console.log(err);
          trackEvent({
            optimusEventType: OnboardingEventType,
            optimusEvents: [{
              name: 'get-company-roles-failed',
              detail: {
                error: err,
              },
            }],
            solutionOrg: getSolutionOrgName(solutionOrgId),
            apiResponseErrorStatus: (err as AxiosError)?.response?.status,
          });
          const responseError: ResponseError = { errorMessage: 'failed to get company roles' };
          return rejectWithValue(responseError as ResponseError);
        }
      },
      );

export const enrollMemberToReward = createAsyncThunk<
  // Return type of the payload creator
  EnrollmentResponse,
  // First argument to the payload creator
  {
    solutionOrgId: string;
    request: EnrollmentRequest;
  },
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'onboarding/enrollMemberToReward',
    async ({ solutionOrgId, request }, { rejectWithValue, getState }) => {
      try {
        // filter null field from EnrolledMember object
        request.member = filterNullFields(request.member);
        console.log(`enrollment request payload: ${JSON.stringify(request)}`);
        const response = await onboardingAPI.enrollMemberToReward(solutionOrgId, request);
        return response as EnrollmentResponse;
      } catch (err: unknown) {
        // TODO: handle request errors appropriately
        const responseError = ((err as AxiosError)?.response?.data ||
          { errorMessage: 'something went wrong' }) as ResponseError;
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const updatePartnersOfMember = createAsyncThunk<
  // Return type of the payload creator
  UpdatePartnersOfMemberResponse,
  // First argument to the payload creator
  {
    memberId: string;
    payload: UpdatePartnersOfMemberRequest;
  },
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState;
  }>(
    'onboarding/updatePartnersOfMember',
    async (request, { rejectWithValue }) => {
      try {
        const response =
          await onboardingAPI.updatePartnersOfMember(request.memberId, request.payload);
        return response as UpdatePartnersOfMemberResponse;
      } catch (err: unknown) {
        // TODO: handle request errors appropriately
        const responseError = ((err as AxiosError)?.response?.data ||
          { errorMessage: 'something went wrong' }) as ResponseError;
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );

export const getCompanyEnrollmentInformation = createAsyncThunk<
  // Return type of the payload creator
  CompanyEnrollment | null,
  // First argument to the payload creator
  {
    rewardProgramId: string,
    memberId: string
  },
  // Types for ThunkAPI
  {
    rejectValue: ResponseError;
    state: RootState
  }>(
    'onboarding/getCompanyEnrollmentInformation',
    async ({ rewardProgramId, memberId }, { rejectWithValue, getState }) => {
      const { memberInformation } = getState().onboarding;
      try {
        // Get company Id
        const companyId = memberInformation?.company?.id || (await onboardingAPI
          .getMemberInformation(memberId) as Member)?.company?.id;

        if (companyId) {
          const response = await onboardingAPI.getCompanyEnrollmentInformation(
            companyId,
            rewardProgramId,
          );
          return response as CompanyEnrollment;
        }
        return null;
      } catch (err) {
        console.log(err);
        const responseError: ResponseError = {
          errorMessage: 'failed to get company enrollment information',
          apiResponseErrorStatus: (err as AxiosError)?.response?.status,
        };
        return rejectWithValue(responseError as ResponseError);
      }
    },
  );
