import { createContext, PropsWithChildren, useContext } from 'react';

export interface FeatureFlags {
  contentFlag?: boolean;
  verificationEmailFlag?: boolean;
  webPocFlag?: boolean;
  saveContentCardFlag?: boolean;
  useContentFromOpenSearchFlag?: boolean;
  searchFlag?: boolean
  hdOnboardingForSNFlag?: boolean
  homeDepotOnlySN?: boolean
  homeDepotOnlyEverPro?: boolean
  behrFlag?: boolean
  connectWithPaypalFlag?: boolean
  discountFlag?: boolean
  hdSupplyFlag?: boolean
}

export interface FeatureFlagProps extends PropsWithChildren {
  /**
  * Feature flags for the app.
   */
  featureFlags: FeatureFlags;
}

/**
 * Context for holding the feature flag values for the app.
 */
const FeatureFlagContext = createContext<FeatureFlags>({});

/**
 * Provider for the feature flags.
 * We can add some custom logic on the flags over here as required.
 */
export const FeatureFlagProvider = ({ children, featureFlags = {} }: FeatureFlagProps) => (
<FeatureFlagContext.Provider value={featureFlags}>
  {children}
</FeatureFlagContext.Provider>
);

/**
 *
 * @returns Feature flags available in the context.
 */
export const useFeatureFlags = () => {
  const featureFlags = useContext(FeatureFlagContext);

  return featureFlags;
};
