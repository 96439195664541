import {
  CreateOrUpdatePaymentMethodInput, AddMemberToPartnerInput, VerifyMemberPaypalIdentityInput,
  AddOrUpdateMemberConfigurationInput, SaveCompanyPartnerRelationInput, UpdateCompanyInput,
  SignUpMemberForHdSupplyInput,
} from '@optimus/models';
import { axiosInstance } from './apiConfig';

export const getPayPalAccount = async (memberId: string | undefined) => {
  const { data } = await axiosInstance.get(`/members/${memberId}/payment-methods`);
  return data;
};

export const getLennoxMemberRelation = async (memberId: string, lennoxPartnerId: string) => {
  const { data } = await axiosInstance.get(`/members/${memberId}/partners/${lennoxPartnerId}`);
  return data;
};

export const getHomeDepotMemberRelation = async (memberId: string, hdPartnerId: string) => {
  const { data } = await axiosInstance.get(`/members/${memberId}/partners/${hdPartnerId}`);
  return data;
};

export const setPayPalAccountId =
  async (
    memberId: string | undefined,
    request: CreateOrUpdatePaymentMethodInput,
  ) => {
    const { data } = await axiosInstance.post(`/members/${memberId}/payment-methods`, request);
    return data;
  };

export const setLennoxMemberKey =
  async (
    memberId: string,
    partnerId: string,
    requestBody: AddMemberToPartnerInput,
  ) => {
    const { data } = await axiosInstance.put(`/members/${memberId}/partners/${partnerId}`, requestBody);
    return data;
  };

export const updateMemberToPartner =
  async (
    memberId: string,
    partnerId: string,
    requestBody: AddMemberToPartnerInput,
  ) => {
    const { data } = await axiosInstance.put(`/members/${memberId}/partners/${partnerId}`, requestBody);
    return data;
  };

export const deleteMemberPartnerRelation =
  async (
    memberId: string,
    partnerId: string,
  ) => {
    const { data } = await axiosInstance.delete(`/members/${memberId}/partners/${partnerId}`);
    return data;
  };

export const getSelectedPartnersForMember =
  async (
    memberId: string,
  ) => {
    const { data } = await axiosInstance.get(`/members/${memberId}/partners`);
    return data;
  };

export const resendVerficationEmail =
  async (
    memberId: string | undefined,
    paymentMethodId: string | undefined,
  ) => {
    const { data } = await axiosInstance.post(`/members/${memberId}/payment-methods/${paymentMethodId}/resend-email`);
    return data;
  };

export const getYearlyPaymentsById =
  async (
    memberId: string,
  ) => {
    const { data } = await axiosInstance.get(`/payment-details/year/${memberId}`);
    return data;
  };

export const updateMemberConfig = async (
  request: AddOrUpdateMemberConfigurationInput,
  memberId: string,
  configurationId: string,
) => {
  const { data } = await axiosInstance.put(
    `/members/${memberId}/configurations/${configurationId}`,
    request,
  );
  return data;
};

export const getMemberConfig = async (memberId: string) => {
  const { data } = await axiosInstance.get(`/members/${memberId}/configurations`);
  return data;
};

export const createMemberConfig = async (
  request: AddOrUpdateMemberConfigurationInput,
  memberId: string,
) => {
  const { data } = await axiosInstance.post(`/members/${memberId}/configurations`, request);
  return data;
};

export const updateCompanyConfig = async (
  request: SaveCompanyPartnerRelationInput,
) => {
  const { data } = await axiosInstance.post('/company-partner-relations', request);
  return data;
};

export const updateCompanyInformation = async (
  request: UpdateCompanyInput,
  companyId: string,
) => {
  const { data } = await axiosInstance.put(`/companies/${companyId}`, request);
  return data;
};

export const signUpMemberForHdSupply = async (
  request: SignUpMemberForHdSupplyInput,
  memberId: string,
) => {
  const { data } = await axiosInstance.post(`/members/${memberId}/hd-supply-signup`, request);
  return data;
};

export const getHDSupplyMemberRelation = async (memberId: string, hdSupplyPartnerId: string) => {
  const { data } = await axiosInstance.get(`/members/${memberId}/partners/${hdSupplyPartnerId}`);
  return data;
};

export const verifyAndUpdatePaypalEmail = async (
  memberId: string,
  request: VerifyMemberPaypalIdentityInput,
) => {
  const { data } = await axiosInstance.post(`/members/${memberId}/paypal-verification`, request);
  return data;
};
