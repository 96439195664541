import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import {
  Alert, Backdrop, CircularProgress, Snackbar, Typography,
} from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import {
  addPartnerInformationBtnText,
  addSelectedPartners,
  selectMemberInformation,
  selectMemberPartnerRelation,
  selectMemberSession,
  selectOnboardingForm,
  selectOnboardingFormAnswers,
  selectPartnerInformationBtnText,
  selectPartnersInformationQuestions,
  selectSelectedPartners,
  updateFormValidState,
  updateNavigationFromOnboarding,
} from '../../store/slices/onboardingSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ONBOARDING_FORMS } from './constants';
import InputComponent from '../../common/QuestionComponent';
import { Question, QuestionOption } from '../../common/models/Question';
import { EnrollmentRequest } from '../../common/models/EnrollmentRequest';
import { enrollMemberToReward } from '../../store/thunks/onboardingThunk';
import { Partner } from '../../common/models/Partner';
import {
  JoistSolutionOrgName, PartnersInformationQuestionTag,
} from '../../common/constants';
import { useSolutionOrg } from '../../config/useSolutionOrg';
import { useFeatureFlags } from '../../config/FeatureFlagsProvider';
import { useOnboardEventTracking } from '../../common/useOnboardEventTracking';
import { HOME_DEPOT_SETUP_OR_SKIP_ROUTE } from '../../routes/namedRoutes';

interface OnboardingPartnersInformationProps {
  setIsOnboarding: ((value: boolean) => void) | null;
  submitMarketoData: (email: string) => void;
  setButtonText?: (text: string) => void;
}
export function OnboardingPartnersInformation({
  setIsOnboarding,
  submitMarketoData,
  setButtonText,
}:
  OnboardingPartnersInformationProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const partnersInformationQuestions = useAppSelector(selectPartnersInformationQuestions);
  const questionTitleAndIdMap = new Map<string, string>();
  partnersInformationQuestions?.forEach((question: Question, index: number) => {
    questionTitleAndIdMap.set(question.meta?.memberProperty as string ||
      question.title + index, question.id);
  });
  const StoreSelectedPartners = useAppSelector(selectSelectedPartners);
  const [defaultSelectedPartners, setDefaultSelectedPartners] = useState(StoreSelectedPartners);
  const partnerInformationBtnText = useAppSelector(selectPartnerInformationBtnText);
  const memberInformation = useAppSelector(selectMemberInformation);
  const memberPartnerRelation = useAppSelector(selectMemberPartnerRelation);
  const onboardingForm = useAppSelector(selectOnboardingForm);
  const onboardingFormAnswers = useAppSelector(selectOnboardingFormAnswers);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  // home depot partner id
  const hdPartnerId = process.env.REACT_APP_HOME_DEPOT_PARTNER_ID || '';
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const memberSession = useAppSelector(selectMemberSession);
  const {
    envRewardProgramId,
    solutionOrgName,
    envEnableMarketoDataSubmission,
    envSolutionOrgId,
    isIframe,
  } = useSolutionOrg();
  const { hdOnboardingForSNFlag } = useFeatureFlags();
  const trackOnboardEvent = useOnboardEventTracking();

  const {
    handleSubmit,
    control,
    formState,
    trigger,
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (partnerInformationBtnText) {
      setButtonText?.(partnerInformationBtnText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerInformationBtnText]);

  useEffect(() => {
    setDefaultSelectedPartners(StoreSelectedPartners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StoreSelectedPartners]);

  useEffect(() => {
    dispatch(updateFormValidState(formState.isValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  const getDefaultValue = (question: Question) => {
    if (question.meta?.behavior === 'imageOption' && defaultSelectedPartners.length > 0) {
      return question.options?.map((questionOption) => ({
        ...questionOption,
        isSelected: defaultSelectedPartners.some(
          (defaultSelectedPartner) => defaultSelectedPartner.partnerId === questionOption.value,
        ),
      }));
    }
    // remove Home Depot partner if hdOnboardingForSNFlag is not on
    if (!hdOnboardingForSNFlag) {
      return question.options?.filter((item) => item.value !== hdPartnerId);
    }
    return question.options;
  };

  const prepareEnrollmentRequest = (selectedPartners: Partner[]) => {
    const {
      id, email, companyRole, company, ...enrolledMember
    } = memberInformation;
    const request: EnrollmentRequest = {
      memberId: memberInformation.id,
      rewardProgramId: (envRewardProgramId as string) || '',
      member: enrolledMember,
      forms: [
        {
          formId: onboardingForm.id,
          answers: [...onboardingFormAnswers],
        },
      ],
      // TODO: handle the case to select Behr on selection of Home depot
      partners: selectedPartners.map((selectedPartner) => {
        if (selectedPartner.partnerId === hdPartnerId) {
          return {
            ...selectedPartner,
            ...(memberPartnerRelation?.memberEmail ? {
              partnerMemberEmail: memberPartnerRelation.memberEmail,
            } : {}),
          };
        }
        return selectedPartner;
      }),
    };
    const solutionOrgId = (envSolutionOrgId as string) || '';
    dispatch(enrollMemberToReward({ solutionOrgId, request }))
      .then(unwrapResult)
      .then(() => {
        setOpenSuccessSnackbar(true);
        setOpenErrorSnackbar(false);
        setLoading(false);

        // send a rewards everpro signup completed event
        trackOnboardEvent('rewards_everpro_signup_completed');

        // send marketo data
        if (envEnableMarketoDataSubmission as boolean) {
          submitMarketoData(memberSession.memberEmail);
        }

        // Check if:
        // 1. This is an iframe
        // 3. Home depot is selected
        if (isIframe &&
        selectedPartners.some((selectedPartner) => selectedPartner.partnerId === hdPartnerId)) {
        // move to the home depot splash screen
          navigate(HOME_DEPOT_SETUP_OR_SKIP_ROUTE);
        } else {
        // navigate to content home page
          dispatch(updateNavigationFromOnboarding(true));
          navigate('/home', {
            state: {
              fromOnboarding: true,
            },
          });
        }
      })
      .catch((error: unknown) => {
        setOpenSuccessSnackbar(false);
        setOpenErrorSnackbar(true);
        setLoading(false);
        if (setIsOnboarding) setIsOnboarding(false);
        // send a rewards everpro signup failed event
        trackOnboardEvent('rewards_everpro_signup_failed', { error });
      });
  };

  const generateSelectedPartners = (
    questionOptions: QuestionOption[],
    selectedPartners: Partner[],
  ) => {
    const selectedValues = questionOptions.filter((item: QuestionOption) => item.isSelected);
    selectedValues.forEach((item: QuestionOption) => {
      // goes to partner object
      const partner: Partner = {
        partnerId: item.value,
      };
      selectedPartners.push(partner);
    });
  };

  const onSubmit = (data: FieldValues) => {
    if (data) {
      if (setIsOnboarding) setIsOnboarding(true);
      const selectedPartners: Partner[] = [];
      Object.keys(data).forEach((key: string) => {
        generateSelectedPartners(data[key], selectedPartners);
      });

      setLoading(true);
      prepareEnrollmentRequest(selectedPartners);
    }
  };

  const handleClose = () => {
    setOpenErrorSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          // mt: 7,
        }}
      >
        <form id={ONBOARDING_FORMS.partnersInformation.id} onSubmit={handleSubmit(onSubmit)}>
          <Snackbar
            open={openSuccessSnackbar}
            autoHideDuration={solutionOrgName !== JoistSolutionOrgName ? 4000 : null}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert severity="success" sx={{ width: '100%' }}>
              {t('success.generalMessage')}
            </Alert>
          </Snackbar>
          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {t('error.generalMessage')}
            </Alert>
          </Snackbar>
          {!setIsOnboarding &&
            <Backdrop
              sx={(theme) => ({
                color: theme.palette.common.white, zIndex: () => theme.zIndex.drawer + 1,
              })}
              open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>}
          <Box>
            {partnersInformationQuestions?.map(
              (question: Question, index: number) => {
                const controllerName = question.meta?.memberProperty as string ||
                  question.title + index;
                return (
                  <Box key={question.id}>
                    <Typography sx={(theme) => ({
                      mt: 2,
                      textAlign: 'left',
                      mb: '2px',
                      color: theme.palette.color3.main,
                      [theme.breakpoints.down('lg')]: {
                        marginBottom: theme.spacing(3),
                      },
                    })}>
                      {question.title}
                    </Typography>
                    <Controller
                      name={controllerName}
                      control={control}
                      rules={question.meta?.rules || {}}
                      defaultValue={getDefaultValue(question)}
                      render={(
                        {
                          field: {
                            onChange, value,
                          },
                        },
                      ) => (
                        <div>
                          <InputComponent
                            type={question.type}
                            onChange={(e) => {
                              if (question.tags?.some(
                                (tag) => tag === PartnersInformationQuestionTag,
                              )) {
                                if (isIframe &&
                                  (e as QuestionOption[])?.some(
                                    (questionOption) => questionOption.value === hdPartnerId &&
                                      questionOption.isSelected,
                                  )
                                ) {
                                  dispatch(addPartnerInformationBtnText(`${t('button.next')}`));
                                } else {
                                  dispatch(addPartnerInformationBtnText(`${t('button.done')}`));
                                }

                                const selectedPartners: Partner[] = [];
                                generateSelectedPartners(e as QuestionOption[], selectedPartners);
                                dispatch(addSelectedPartners(selectedPartners));
                              }
                              onChange(e); // Ensure React Hook Form's internal state is updated
                            }}
                            inputValue={value}
                            meta={question.meta}
                            options={getDefaultValue(question)}
                            placeholderText={question.title ? question.title : ''}
                          />
                        </div>
                      )
                      }
                    />
                  </Box>
                );
              },
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
}
