import Box from '@mui/material/Box';
import {
  Theme,
} from '@mui/material';
import Container from '@mui/material/Container';
import { SystemStyleObject } from '@mui/system';
import { useState } from 'react';
import { LoadingComponent } from '../../../common/LoadingComponent';
import { OnboardForm } from './OnboardForm';
import { OnboardBusinessInformation } from '../BusinessInformation/OnboardBusinessInformation';
import { OnboardingCompanyInformation } from '../CompanyInformation/OnboardingCompanyInformation';
import { ReactComponent as JoistRewardsMainLogo }
  from '../../../assets/images/joist_rewards_main_logo.svg';
import { useCompanyEnrollmentInfo } from '../../../common/useCompanyEnrollmentInfo';

const containerStyle = (theme: Theme) => ({
  textAlign: 'center',
  marginTop: theme.spacing(2),
} as const);

interface OnboardHomeProps {
  optionalContainerStyle?: (theme: Theme) => SystemStyleObject<Theme>;
  OptionalHeader?: JSX.Element;
  optionalBtnStyles?: {
    [k: string]: string;
  }
}

export function OnboardHomeJoist({
  optionalContainerStyle,
  OptionalHeader,
  optionalBtnStyles,
}: OnboardHomeProps) {
  const [isOnboarding, setIsOnboarding] = useState(false);
  const numberOfOnboardingForms = 2;
  const onboardFormId = process.env.REACT_APP_FORM_ID;

  // check company enrollment status
  useCompanyEnrollmentInfo({
    calledFromOnboardingComponent: true,
    redirectPath: '/in-app/dashboard',
  });

  return (
    <div style={{ position: 'relative' }}>
      {isOnboarding && optionalContainerStyle && <LoadingComponent />}
      <Container maxWidth='xs' sx={optionalContainerStyle ? [containerStyle, optionalContainerStyle] : [containerStyle]}>
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2,
        }}>
          <JoistRewardsMainLogo />
          <OnboardForm
            onboardFormId={onboardFormId || ''}
            optionalContainerStyle={optionalContainerStyle}
            optionalBtnStyles={optionalBtnStyles}
            setIsOnboarding={setIsOnboarding}
            OptionalHeader={OptionalHeader}
            numberOfQuestions={numberOfOnboardingForms as number}
            companyInformationComponent={() => <OnboardingCompanyInformation
              disableEmail={true}
              calledFromOnboarding={true}/>}
            businessInformationComponent={() => <OnboardBusinessInformation
              setIsOnboarding={optionalContainerStyle ? setIsOnboarding : null} />}
          />
        </Box>
      </Container>
    </div>
  );
}
