import {
  Box, SvgIcon, Theme, Typography, Collapse, List, ListItemButton, ListItemText,
  Link,
  ListItemIcon,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { SxProps } from '@mui/system';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import React, { useEffect, useState } from 'react';
import theme from '../../assets/theme';
import { DashboardSubMenuItemsProps } from './DashboardDesktopLayout';

export interface DashboardNavElementProps {
  sx: SxProps<Theme>;
  name: string;
  path: string;
  outlineIcon: typeof SvgIcon;
  filledIcon: typeof SvgIcon;
  isSelected: boolean;
  onClick: () => void;
  subMenuItems?: DashboardSubMenuItemsProps[];
  bottomFixPosition?: boolean;
}

const NavElementStyle = () => ({
  display: 'block',
  alignItems: 'center',
  color: theme.palette.color2.main,
  '&:hover': {
    color: theme.palette.common.black,
    cursor: 'pointer',
  },
} as const);

const NavIconStyle = () => ({
  width: theme.spacing(4.5),
  height: theme.spacing(3.8),
} as const);

const NavTypographyStyle = () => ({
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(3),
  },
} as const);

export function DashboardNavElement(props: DashboardNavElementProps) {
  const navigate = useNavigate();
  const {
    sx, path, isSelected,
  } = props;

  const [openSubMenu, setOpenSubMenu] = useState(false);
  const location = useLocation(); // Get the current location

  const handleClick = () => {
    if (props.subMenuItems?.length) {
      setOpenSubMenu(!openSubMenu);
    } else {
      setOpenSubMenu(false);
      props.onClick();
      if (path !== '#') navigate(path);
    }
  };

  // Close all collapses on navigation
  useEffect(() => {
    if (!location.pathname.includes(path)) setOpenSubMenu(false);
  }, [location.pathname, path]); // Dependency on location.pathname

  const handleSubMenuClick = (navPath: string, e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.stopPropagation) e.stopPropagation();
    if (props.onClick) props.onClick();
    navigate(navPath);
  };

  const Icon = () => {
    if (props.subMenuItems?.length) {
      return (openSubMenu ? <KeyboardArrowDownIcon sx={[NavIconStyle]} /> :
        <KeyboardArrowRightIcon sx={[NavIconStyle]} />);
    }
    return (isSelected ?
      <props.filledIcon sx={[NavIconStyle]} /> :
      <props.outlineIcon sx={[NavIconStyle]} />);
  };

  return (<Link style={{ textDecoration: 'none' }} >
    <Box
      sx={[
        NavElementStyle,
        openSubMenu ? {
          color: theme.palette.common.black,
          flexFlow: 'wrap',
          [theme.breakpoints.down('md')]: {
            display: 'block',
          },
        } : {},
        props.bottomFixPosition ? {
          position: 'fixed',
          bottom: theme.spacing(0),
          paddingBottom: `${theme.spacing(3)} !important`,
        } : {},
        props.isSelected ? {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          borderRadius: theme.spacing(1.5),
          border: `1px solid ${theme.palette.grey3.main}`,
        } : {},
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'end',
        }}
      >
        <Icon />
        <Typography variant='h5' sx={NavTypographyStyle}>{props.name}</Typography>
      </Box>
      {props.subMenuItems?.length &&
        <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding
            sx={{
              pl: theme.spacing(2.25),
              mt: theme.spacing(0),
            }}>
            <List component="div" disablePadding>
              {props.subMenuItems?.map((option, index) => (
                <Box
                  sx={{
                    pl: 4,
                    color: theme.palette.common.black,
                    fontWeight: 'bold',
                    display: 'flex',
                  }}
                  key={option.title}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 'revert',
                      alignSelf: 'center',
                    }}
                  ><SubdirectoryArrowRightIcon /></ListItemIcon>
                  <ListItemButton
                    key={index}
                    sx={{
                      pt: theme.spacing(0.5),
                      pb: theme.spacing(0.5),
                      pl: theme.spacing(1),
                    }}
                    onClick={(e) => handleSubMenuClick(option.path, e)}
                  >
                    <ListItemText
                      sx={{
                        color:
                          option.isCurrent ? theme.palette.common.black : theme.palette.color2.main,
                        alignSelf: 'flex-start',
                      }}
                      primary={option.title} />
                  </ListItemButton>
                </Box>
              ))}
            </List>
          </List>
        </Collapse>}
    </Box>
  </Link>);
}
