/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Box, Container, Drawer, Theme, useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { DashboardNavElement } from './DashboardNavElement';
import { useFeatureFlags } from '../../config/FeatureFlagsProvider';
import { useLogo } from '../../config/useLogo';
import { DashboardScreensLayoutProps } from './DashboardLayout';

const sxAlignRight = {
  marginLeft: 'auto',
};

const sxIconStyle = (theme: Theme) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  padding: '8px',
  color: theme.palette.color2.main,
} as const);

export function DashboardMobileLayout({
  children,
  navElems,
}: DashboardScreensLayoutProps) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { searchFlag } = useFeatureFlags();
  const { secondaryOne: NavLogo } = useLogo();

  const goToSearch = () => {
    setOpen(false);
    navigate('/search');
  };

  const DrawerList = (
    <Box>
      {
        navElems.map((elem, index) => (
          <DashboardNavElement
            sx={{
              marginBottom: theme.spacing(1.25),
              padding: theme.spacing(1.875),
            }}
            key={index}
            name={elem.name}
            path={elem.path}
            outlineIcon={elem.Outline}
            filledIcon={elem.Filled}
            isSelected={elem.isCurrent ?? false}
            onClick={() => {
              if (elem.onClick) elem.onClick();
              setOpen(false);
            }}
            subMenuItems={elem.subMenuItems}
            bottomFixPosition={elem.bottomFixPosition}
          />
        ))
      }
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.12),0px 0px 4px 0px rgba(0, 0, 0, 0.14)',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          zIndex: 1201,
        }}
      >
        <Box sx={[{
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: theme.spacing(1.5),
          height: theme.spacing(6),
          width: theme.spacing(6),
          padding: theme.spacing(1),
        }, { ...(open && { background: theme.palette.color25.main }) }]}>
          <MenuIcon sx={[{
            width: theme.spacing(4),
            height: theme.spacing(4),
            cursor: 'pointer',
            color: theme.palette.color2.main,
            fontWeight: 500,
          }, { ...(open && { color: theme.palette.secondary.main }) }]}
            onClick={() => setOpen(!open)}
          />
        </Box>
        {NavLogo && <NavLogo
          height={34}
          style={{
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            marginLeft: theme.spacing(1.375),
            width: theme.spacing(19.75),
          }}
        />}
        {searchFlag && <Box sx={[sxAlignRight, sxIconStyle]}
          onClick={goToSearch}>
          <SearchIcon sx={{ width: '100%', height: '100%' }} />
        </Box>}
      </Box>
      <Drawer open={open} onClose={() => setOpen(false)} PaperProps={{
        sx: {
          width: '100%',
          paddingTop: theme.spacing(13),
          pr: theme.spacing(1.25),
          pl: theme.spacing(1.25),
          backgroundColor: theme.palette.grey[100],
        },
      }} >
        {DrawerList}
      </Drawer>
      <Container maxWidth={'sm'}
        sx={{
          pt: theme.spacing(2.5),
          pb: theme.spacing(2.5),
          pr: theme.spacing(1.25),
          pl: theme.spacing(1.25),
        }}
      >
        {children}
      </Container>
    </>
  );
}
