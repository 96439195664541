import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import OnboardingMobileLayout from './OnboardingMobileLayout';
import OnboardingDesktopLayout from './OnboardingDesktopLayout';
import NavHeader from '../../../../common/NavHeader';

export interface OnboardingLayoutProps {
  children?: JSX.Element,
  splashScreenNav?: JSX.Element
  reduceMobileTopPadding?: boolean;
  customDesktopBanner?: JSX.Element | null
}
export default function OnboardingLayout({
  children,
  splashScreenNav,
  reduceMobileTopPadding,
  customDesktopBanner,
}: OnboardingLayoutProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <NavHeader />
      {
        isDesktop ? <OnboardingDesktopLayout
        splashScreenNav={splashScreenNav}
        customBanner={customDesktopBanner}
        >
          {children}
        </OnboardingDesktopLayout> :
          <OnboardingMobileLayout reduceMobileTopPadding={reduceMobileTopPadding}>
            {children}
          </OnboardingMobileLayout>
      }
    </>
  );
}
