import {
  Box,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import {
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { selectMemberInformation } from '../../../store/slices/onboardingSlice';
import { useAppSelector } from '../../../store/hooks';
import {
  Body8VariantTypography, EditOption, ErrorAlert, IFormInputs, StyledTextField,
} from './HDSupplySignupFormDialog';

export function MemberInformationFormControl({ hasEmptyFields = false }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const memberInfo = useAppSelector(selectMemberInformation);
  const [editMemberName, setEditMemberName] = useState(hasEmptyFields);
  const { register, formState: { errors } } = useFormContext<IFormInputs>();

  return (
    <>  <Typography variant="h2" gutterBottom>
      {t('hdSupply.memberInformation.heading')}
    </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={10} md={10} lg={10}>
          <Body8VariantTypography>{t('hdSupply.memberInformation.name') || ''}</Body8VariantTypography>
        </Grid>
        {!editMemberName &&
          <Grid item xs={4} sm={2} md={2} lg={2}>
            <EditOption updateEditState={(newState: boolean) => setEditMemberName(newState)} />
          </Grid>
        }
      </Grid>
      {editMemberName ? <Box>
        <Box sx={{ mb: theme.spacing(2) }}>
          <Body8VariantTypography>{`${t('onboarding.questionPlaceholder.First Name')}*` || ''}</Body8VariantTypography>
          <StyledTextField
            placeholder='First Name'
            {...register('memberInfo.firstName', { required: true })}
          />
          {(errors.memberInfo?.firstName) && <ErrorAlert />}
        </Box>
        <Box sx={{ mb: theme.spacing(2) }}>
          <Body8VariantTypography>{`${t('onboarding.questionPlaceholder.Last Name')}*` || ''}</Body8VariantTypography>
          <StyledTextField
            placeholder='Last Name'
            {...register('memberInfo.lastName', { required: true })}
          />
          {(errors.memberInfo?.lastName) && <ErrorAlert />}
        </Box>
      </Box> : <Box>
        <Typography variant='body4'> {memberInfo.firstName}  {memberInfo.lastName}</Typography>
      </Box>}</>
  );
}
