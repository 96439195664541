import {
  Autocomplete, Paper, TextField, useTheme,
} from '@mui/material';
import React from 'react';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { AutocompleteInputChangeReason } from '@mui/base/useAutocomplete/useAutocomplete';
import { QuestionOption } from '../models/Question';

type QuestionComponentAutocompleteProps = {
  inputValue: QuestionOption | null;
  placeholderText: string;
  options?: QuestionOption[];
  onChange: (newValue: QuestionOption | null) => void;
  onBlur: ()=>void;
  calledFromOnboarding?: boolean;
} & Omit<AutocompleteProps<QuestionOption, false, false, false>, 'inputValue' | 'renderInput'>;

export const QuestionComponentAutocomplete = (props: QuestionComponentAutocompleteProps) => {
  const {
    onChange, options, inputValue, onBlur, placeholderText, calledFromOnboarding, ...args
  } = props;

  const [open, setOpen] = React.useState(false);
  const [searchString, setSearchString] = React.useState('');
  const theme = useTheme();

  const onOpen = () => {
    if (searchString) {
      setOpen(true);
    }
  };
  const onClose = () => {
    setOpen(false);
  };

  const onInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    setSearchString(value);
  };

  const sxHideClearButton = {
    '& div .MuiAutocomplete-popupIndicator': {
      display: 'none !important',
    },
  };

  const sxTextField = () => ({
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  });

  return (
    <Autocomplete
      classes={{ ...props.classes }}
      sx={{
        ...sxHideClearButton,
      }}
      PaperComponent={({ children }) => (
        <Paper sx={{
          ...(calledFromOnboarding ? {
            border: `1px solid ${theme.palette.grey3.main}`,
          } : {}),
        }}>
          {children}
        </Paper>
      )}
      disablePortal
      onBlur={onBlur}
      options={options}
      value={props.inputValue}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      onInputChange={onInputChange}
      onChange={(event, newValue) => props.onChange(newValue)}
      getOptionLabel={(option: QuestionOption) => option.display}
      openOnFocus={false}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          sx={[sxTextField, {
            ...(calledFromOnboarding ? {
              borderRadius: theme.spacing(1.5),
              border: '1px solid var(--Base-Color-Neutral-N15, #E2E2E4)',
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.00), 0px 0px 0px 0px rgba(0, 0, 0, 0.00)',
              paddingTop: theme.spacing(0),
              paddingBottom: theme.spacing(0),
              '& .MuiOutlinedInput-input': {
                // color: theme.palette.color20.main,
              },
            } : {}),
          }]}
          {...params}
          placeholder={calledFromOnboarding ? '' : placeholderText}
          id={placeholderText}
        />
      )}
      {...args}
    />
  );
};
