import {
  Card, CardContent, Grid, Skeleton, Theme, ThemeProvider,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { ContentTheme } from './ContentTheme';
import ContentCardHeaderSkeleton from './ContentCardHeaderSkeleton';

interface ContentCardSkeletonProps {
  sx?: SxProps<Theme>
}

export default function ContentCardSkeleton(props: ContentCardSkeletonProps) {
  const numberOfCards = 5;
  return (
    <ThemeProvider theme={ContentTheme}>
      {[...Array(numberOfCards).keys()].map((item, index) => (
        <Grid item key={index}
          sx={[
            (theme) => ({
              paddingTop: theme.spacing(3),
              paddingLeft: theme.spacing(3),
              [theme.breakpoints.down('md')]: {
                paddingTop: theme.spacing(2),
                paddingLeft: theme.spacing(2),
              },
              [theme.breakpoints.up('md')]: {
                width: theme.spacing(86.25),
              },
            }),
            ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
          ]}
        >
          <Card sx={{
            minWidth: 275,
            width: '100%',
            borderRadius: '10px',
            boxShadow: 0,
            padding: '14px',
          }}>
            <div>
              <CardContent sx={{
                padding: '0px',
                paddingLeft: '12px',
                paddingRight: '12px',
                [ContentTheme.breakpoints.down('sm')]: {
                  paddingBottom: '10px',
                },
              }}>
                <ContentCardHeaderSkeleton />
              </CardContent>
              <Skeleton
                sx={(theme) => ({
                  borderRadius: '10px',
                  [theme.breakpoints.down('sm')]: {
                    height: 180,
                  },
                  height: 250,
                })}
                animation="wave" variant="rectangular" />
              <Grid container textAlign={'center'}
                justifyContent={'space-between'}
                sx={{
                  padding: '32px 32px 0 40px',
                }}
              >
                <Grid item xs={4} sm={2}>
                  <Skeleton
                    animation="wave"
                    height={15}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Skeleton
                    animation="wave"
                    height={15}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
      ))
      }
    </ThemeProvider >);
}
