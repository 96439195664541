import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  Card,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectGetHomeDepotMemberRelationState, selectHomeDepotMemberRelation } from '../store/slices/dashboardSlice';
import { HDVerificationStatus } from './enums';
import { getHomeDepotMemberRelation } from '../store/thunks/dashboardThunk';

interface PromotionCardProps {
  promotionText: string;
  promotionButtonText: string;
  promotionImage?: React.ComponentType;
  optionalStyles?: React.CSSProperties;
  onCardClose?: () => void;
  onCardClickButton?: () => void;
}

export function PromotionCard({
  promotionText,
  promotionButtonText,
  promotionImage: PromotionImageComponent,
  onCardClose,
  onCardClickButton,
  optionalStyles = {},
}: PromotionCardProps) {
  const theme = useTheme();
  const hdMemberRelation = useAppSelector(selectHomeDepotMemberRelation);
  const getHomeDepotMemberRelationState = useAppSelector(selectGetHomeDepotMemberRelationState);
  const dispatch = useAppDispatch();
  const [verificationStatus, setVerificationStatus] = useState(HDVerificationStatus.Pending);
  const customBackgroundColor = optionalStyles.backgroundColor || theme.palette.color14.light;
  const customBorderColor = optionalStyles.borderColor || theme.palette.color14.dark;

  useEffect(() => {
    if (!hdMemberRelation) {
      dispatch(getHomeDepotMemberRelation());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check if user is Enrolled to show promotions
  useEffect(() => {
    if (hdMemberRelation?.partnerMemberKey || hdMemberRelation?.partnerMemberEmail) {
      setVerificationStatus(HDVerificationStatus.Completed);
    } else {
      setVerificationStatus(HDVerificationStatus.Pending);
    }
  }, [hdMemberRelation]);

  const onClickClose = () => {
    if (onCardClose) onCardClose();
  };

  const onClickButton = () => {
    if (onCardClickButton) onCardClickButton();
  };

  return (
    (getHomeDepotMemberRelationState === 'idle' && verificationStatus === HDVerificationStatus.Completed ?
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: theme.spacing(34.5),
          width: '100%',
          borderRadius: theme.spacing(1),
          padding: `${theme.spacing(5)} ${theme.spacing(2.5)}`,
          background: customBackgroundColor,
          ...optionalStyles,
        }}
      >
        {/* Button and Body Wrapper */}
        <Box sx={{
          position: 'relative',
          padding: theme.spacing(0),
          letterSpacing: theme.spacing(0.065),
          [theme.breakpoints.down('sm')]: {
          },
        }}>

          {/* Close Button */}
          <Box
            sx={{
              top: `-${theme.spacing(3.75)}`,
              right: 0,
              position: 'absolute',
            }}
          >
            <IconButton sx={{ padding: theme.spacing(0) }} onClick={onClickClose}>
              <CloseIcon sx={{
                color: theme.palette.common.black,
                fontSize: theme.spacing(3),
              }} />
            </IconButton>
          </Box>

          {/* Body Wrapper */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: theme.spacing(2.5),
              padding: `${theme.spacing(0)} ${theme.spacing(3.75)}`,
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
              },
            }}
          >
            {/* Image Section */}
            {PromotionImageComponent &&
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  [theme.breakpoints.down('sm')]: {
                    justifyContent: 'center',
                  },
                }}
              >
                <PromotionImageComponent />
              </Box>
            }

            {/* Text and Button Section */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: theme.spacing(2.5),
                alignSelf: 'stretch',
              }}
            >
              {/* Text Section */}
              <Typography
                variant="body1"
              >
                {promotionText}
              </Typography>

              {/* Button Section */}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  padding: `${theme.spacing(0)} ${theme.spacing(2.5)}`,
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: theme.spacing(0.5),
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: '100%',
                    borderRadius: theme.spacing(12.5),
                    border: `${theme.spacing(0.125)} solid ${customBorderColor}`,
                    background: customBorderColor,
                  }}
                  onClick={onClickButton}
                >
                  {promotionButtonText}
                </Button>
              </Box>

            </Box>
          </Box>
        </Box>
      </Card> :
      null
    )
  );
}
