import { Box } from '@mui/system';
import {
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OnboardingCompanyInformation } from './OnboardingCompanyInformation';
import { useSolutionOrg } from '../../../config/useSolutionOrg';

export function OnboardingCompanyInformationWrapper() {
  const { t } = useTranslation();
  const { solutionOrgSpecificAppName } = useSolutionOrg();
  const theme = useTheme();
  return (
    <Box>
      <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        <Typography component='div' variant='h12' sx={{ textAlign: 'center' }}>
          {t('onboarding.questionPlaceholder.profileHeading1', { solutionOrgSpecificAppName })}
        </Typography>
        <Typography component='div' sx={{
          textAlign: 'center',
          mt: theme.spacing(1.25),
          maxWidth: '70%',
        }}>
          {t('onboarding.questionPlaceholder.profileHeading2')}
        </Typography>
      </Box>
      <Box>
        <OnboardingCompanyInformation disableEmail={true} calledFromOnboarding={true} />
      </Box>
    </Box>
  );
}
