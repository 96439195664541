import { useLocation, useNavigate } from 'react-router-dom';
import {
  updateNavigationFromOnboarding,
} from '../store/slices/onboardingSlice';
import { useAppDispatch } from '../store/hooks';
import { useSolutionOrg } from '../config/useSolutionOrg';

export function useBackToEdgeFromHomeDepot() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isIframe } = useSolutionOrg();
  const location = useLocation();

  const backToEdgeFromHomeDepot = () => {
    // navigate to content home page
    dispatch(updateNavigationFromOnboarding(true));

    const { fromRewards } = location.state || {};
    const redirectPath = fromRewards ? '/rewards' : '/home';
    navigate(redirectPath, {
      state: { fromOnboarding: true },
    });
    if (isIframe) {
      // show optimus app
      window.parent.postMessage({
        payload: { hideOptimusApp: false },
        type: 'OPTIMUS_HIDE_REQUEST',
      }, '*');
    }
  };

  return backToEdgeFromHomeDepot;
}
