import Box from '@mui/material/Box';
import { Theme, Container } from '@mui/material';
import Banner from '../Onboarding/Home/common/Banner';
import { Login } from './Login';

export interface LoginDesktopLayoutProps {
  afterLoginPath?: string
}
export default function LoginDesktopLayout({ afterLoginPath }:
  LoginDesktopLayoutProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        postion: 'relative',
      }}
    >
      <Box
        sx={(theme: Theme) => ({
          flex: 1,
          mt: theme.spacing(6), // Margin Top only for Login page Banner
        })}
      >
        <Banner />
      </Box>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Container maxWidth='sm'
          sx={(theme: Theme) => ({
            textAlign: 'center',
            marginTop: theme.spacing(5),
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),

          })}
        >
          <Login afterLoginPath={afterLoginPath} />
        </Container>
      </Box>
    </Box>
  );
}
