import { useEffect } from 'react';
import './App.css';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AddMemberToPartnerInput } from '@optimus/models';
import { unwrapResult } from '@reduxjs/toolkit';
import { NavigationRoutes } from './routes/navigationRoutes';
import { useAppDispatch, useAppSelector } from './store/hooks';
import {
  addAccessToken, addMemberId, addRewardProgramId, addMemberPartnerRelationMemberEmail,
  addSolutionOrgId, addMemberPartnerRelationPartnerId, OptimusEvent, addMemberDeviceType,
  addMemberDeviceVersion,
  selectMemberSession,
} from './store/slices/onboardingSlice';
import {
  JOIST_COMPLETE_REWARDS_EVERPRO_SIGNUP,
  JOIST_EVENT_BUFFER_RESPONSE,
  JOIST_MEMBER_PARTNER_RELATION_EMAIL_RESPONSE,
  JOIST_READY_FOR_EVENT_BUFFER_REQUEST,
  OPTIMUS_READY_FOR_EVENT_BUFFER_RESPONSE,
  OnboardingEventType,
  REWARDS_PARTNER_CODE_COMPLETED,
  SolutionOrgName,
} from './common/constants';
import { useTracking } from './common/useTracking';
import { customHistory } from './common/utils/loginUtils';
import FaviconManager from './config/FaviconManager';
import { useSolutionOrg } from './config/useSolutionOrg';
import ScrollToTop from './common/ScrollToTop';
import { useBackToEdgeFromHomeDepot } from './common/useBackToEdgeFromHomeDepot';
import { useOnboardEventTracking } from './common/useOnboardEventTracking';
import { updateMemberToPartner } from './store/thunks/dashboardThunk';

function App() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const trackEvent = useTracking();
  const backToEdgeFromHomeDepot = useBackToEdgeFromHomeDepot();
  const trackOnboardEvent = useOnboardEventTracking();
  const memberSession = useAppSelector(selectMemberSession);

  // init custom history object to allow navigation from
  // anywhere in the react app (useful for navigation outside components)
  customHistory.navigate = useNavigate();
  customHistory.location = useLocation();

  const { solutionOrgName, isIframe } = useSolutionOrg();

  useEffect(() => {
    const accessToken = searchParams.get('accessToken');
    const memberId = searchParams.get('memberId');
    const rewardProgramId = searchParams.get('rewardProgramId');
    const solutionOrgId = searchParams.get('solutionOrgId');
    const partnerId = searchParams.get('partnerId');
    // to enable passing the partnerMemberEmail as url query parameter for testing
    const partnerMemberEmail = searchParams.get('partnerMemberEmail');
    const memberDeviceType = searchParams.get('deviceType');
    const memberDeviceVersion = searchParams.get('deviceVersion');

    if (accessToken) {
      dispatch(addAccessToken(accessToken));
    }
    if (memberId) {
      dispatch(addMemberId(memberId));
    }
    if (rewardProgramId) {
      dispatch(addRewardProgramId(rewardProgramId));
    }
    if (solutionOrgId) {
      dispatch(addSolutionOrgId(solutionOrgId));
    }
    if (partnerId) {
      dispatch(addMemberPartnerRelationPartnerId(partnerId));
    }
    if (partnerMemberEmail) {
      dispatch(addMemberPartnerRelationMemberEmail(partnerMemberEmail));
    }
    if (memberDeviceType) {
      dispatch(addMemberDeviceType(memberDeviceType));
    }
    if (memberDeviceVersion) {
      dispatch(addMemberDeviceVersion(memberDeviceVersion));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if this is an iframe
    if (isIframe) {
      window.addEventListener('message', (event) => {
        const message = event?.data;
        if (message?.type === JOIST_MEMBER_PARTNER_RELATION_EMAIL_RESPONSE) {
          if (!(message.payload?.content)) {
            trackOnboardEvent(
              'home_depot_proxtra_email_save_failed',
              {
                error: { msg: 'missing home depot pro extra email' },
              },
            );
          } else {
            const request: AddMemberToPartnerInput = {
              partnerMemberEmail: message.payload.content,
            };

            dispatch(updateMemberToPartner({
              memberId: memberSession?.memberId,
              partnerId: process.env.REACT_APP_HOME_DEPOT_PARTNER_ID || '',
              data: request,
            }))
              .then(unwrapResult)
              .catch((error: unknown) => {
                trackOnboardEvent(
                  'home_depot_proxtra_email_save_failed',
                  {
                    error,
                  },
                );
              });
            dispatch(addMemberPartnerRelationMemberEmail(message.payload?.content));
          }
        } else if (message?.type === JOIST_EVENT_BUFFER_RESPONSE) {
          const optimusEvents = message.payload?.content as OptimusEvent[];
          // check if partner code step is completed
          optimusEvents?.forEach((optimusEvent) => {
            if (optimusEvent.name === REWARDS_PARTNER_CODE_COMPLETED) {
              // update partnerCodeEnteredInMobile column of member partner relation table
              const request: AddMemberToPartnerInput = {
                partnerCodeEnteredInMobile: true,
              };

              dispatch(updateMemberToPartner({
                memberId: memberSession?.memberId,
                partnerId: process.env.REACT_APP_HOME_DEPOT_PARTNER_ID || '',
                data: request,
              }))
                .then(unwrapResult)
                .catch((error: unknown) => {
                  console.log(error);
                });
            }
          });
          trackEvent(
            {
              optimusEventType: OnboardingEventType,
              optimusEvents,
              solutionOrg: solutionOrgName as SolutionOrgName,
            },
          );
        } else if (message?.type === JOIST_READY_FOR_EVENT_BUFFER_REQUEST) {
          window.parent.postMessage({
            type: OPTIMUS_READY_FOR_EVENT_BUFFER_RESPONSE,
            payload: null,
          }, '*');
        }
      });

      // initiate handshake with injected user script in joist
      window.parent.postMessage({ type: 'OPTIMUS_EVENT_BUFFER_REQUEST', payload: null }, '*');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleBackToEdgeFromHomeDepotMsg(event: MessageEvent) {
      if (event?.data?.type === JOIST_COMPLETE_REWARDS_EVERPRO_SIGNUP) {
        backToEdgeFromHomeDepot();
      }
    }

    if (isIframe) {
      window.addEventListener('message', handleBackToEdgeFromHomeDepotMsg);
    }

    return () => {
      if (isIframe) {
        window.removeEventListener('message', handleBackToEdgeFromHomeDepotMsg);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backToEdgeFromHomeDepot]);

  return (
    <>
      <ScrollToTop />
      <FaviconManager />
      <NavigationRoutes />
    </>
  );
}

export default App;
