import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import RewardsQuestions from '../../common/RewardsQuestions';
import { OuterContainer } from './DashboardOuterContainerPagesLayout';

export default function SupportFAQ() {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <OuterContainer>
      <>
        <Typography
          variant="h12"
          sx={{ marginBottom: theme.spacing(3.625) }}
        >
          {t('navigation.faq')}
        </Typography>
        <RewardsQuestions noTitle />
      </>
    </OuterContainer>
  );
}
