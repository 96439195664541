/* eslint-disable no-param-reassign */
import ReactGA from 'react-ga4';
import { flatten } from 'flat';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { OptimusEvent, selectMemberSession } from '../store/slices/onboardingSlice';
import {
  sendContentEventToOptimusBackendThunk,
  sendFeedEventToOptimusBackendThunk,
  sendSearchEventToOptimusBackendThunk,
  sendOnboardingEventToOptimusBackendThunk,
} from '../store/thunks/sendEventToOptimusBackendThunk';
import {
  ContentEventType, FeedEventType, InvoiceSimpleSolutionOrgName, JoistSolutionOrgName,
  KickservSolutionOrgName,
  OnboardingEventType,
  RoofsnapSolutionOrgName, SearchEventType, ServiceFusionSolutionOrgName, SolutionOrgName,
} from './constants';

export interface TrackedEvent {
  optimusEventType:
  typeof OnboardingEventType |
  typeof ContentEventType |
  typeof FeedEventType |
  typeof SearchEventType;
  optimusEvents: OptimusEvent[];
  solutionOrg?: SolutionOrgName;
  apiResponseErrorStatus?: number;
}

export function useTracking() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const memberSession = useAppSelector(selectMemberSession);
  const memberId = memberSession.memberId || searchParams.get('memberId');

  const trackEvent = ({
    optimusEventType,
    optimusEvents,
    solutionOrg,
    apiResponseErrorStatus,
  }: TrackedEvent) => {
    // Do not fire error events for authentication errors
    if (apiResponseErrorStatus && (apiResponseErrorStatus === 401 ||
      apiResponseErrorStatus === 403)) {
      return;
    }
    optimusEvents.forEach((optimusEvent) => {
      // add memberId if missing
      optimusEvent.detail = optimusEvent.detail || {};
      optimusEvent.detail.memberId = optimusEvent.detail.memberId || memberId;

      // add additional properties where appropriate
      if (optimusEventType === OnboardingEventType) {
        // prefix member
        optimusEvent.name = `member_${optimusEvent.name}`;

        // add version to optimus events
        optimusEvent.detail.version = process.env.REACT_APP_GIT_SHA;

        // add reward program id if it's not there
        if (!(optimusEvent.detail.rewardProgramId)) {
          switch (solutionOrg) {
            case JoistSolutionOrgName:
              optimusEvent.detail.rewardProgramId = process.env.REACT_APP_REWARD_PROGRAM_ID;
              break;
            case ServiceFusionSolutionOrgName:
              optimusEvent.detail.rewardProgramId = process.env.REACT_APP_SF_REWARD_PROGRAM_ID;
              break;
            case InvoiceSimpleSolutionOrgName:
              optimusEvent.detail.rewardProgramId =
                process.env.REACT_APP_INVOICE_SIMPLE_REWARD_PROGRAM_ID;
              break;
            case RoofsnapSolutionOrgName:
              optimusEvent.detail.rewardProgramId =
                process.env.REACT_APP_ROOFSNAP_REWARD_PROGRAM_ID;
              break;
            case KickservSolutionOrgName:
              optimusEvent.detail.rewardProgramId =
                process.env.REACT_APP_KICKSERV_REWARD_PROGRAM_ID;
              break;
            default:
              break;
          }
        }
      }

      // fire event to Google Analytics (google analytics convention for event names has underscore)
      // Google analytics only supports sending one event at a time
      ReactGA.gtag('event', optimusEvent.name.replace(/-/g, '_'), flatten(optimusEvent.detail, { delimiter: '_' }));

      // replace '_' with '-'
      optimusEvent.name = optimusEvent.name.replace(/_/g, '-');
    });

    // fire events to optimus backend
    switch (optimusEventType) {
      case OnboardingEventType:
        dispatch(sendOnboardingEventToOptimusBackendThunk({
          optimusEvents,
          solutionOrg,
        }));
        break;
      case ContentEventType:
        dispatch(sendContentEventToOptimusBackendThunk(optimusEvents));
        break;
      case FeedEventType:
        dispatch(sendFeedEventToOptimusBackendThunk(optimusEvents));
        break;
      case SearchEventType:
        dispatch(sendSearchEventToOptimusBackendThunk(optimusEvents));
        break;
      default:
        break;
    }
  };

  return trackEvent;
}
