/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Theme, useMediaQuery, useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  UpdateHDSetupFlowStepsCompletionState,
  selectHDSetupFlowStepsCompletionState,
  selectHomeDepotMemberRelation,
} from '../../../store/slices/dashboardSlice';
import {
  HDSetupFlowSteps, HDVerificationStatus,
} from '../../../common/enums';
import HDStepsCompleteConfirmationCard from './HDStepsCompleteConfirmationCard';
import HDEmailAddressCard from './HDEmailAddressCard';
import HDSetupDoneCard from './HDSetupDoneCard';
import { getHomeDepotMemberRelation } from '../../../store/thunks/dashboardThunk';
import HDSetupStartCard from './HDSetupStartCard';
import CallToActionButton from '../../../common/CallToActionButton';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';
import HDSetupCardHeader from './HDSetupCardHeader';

const cardStyle = (theme: Theme) => ({
  background: theme.palette.common.white,
  borderRadius: theme.spacing(1.6),
  padding: theme.spacing(3, 3.5, 3.5),
  marginTop: theme.spacing(3),
  border: `${theme.spacing(0.125)} solid ${theme.palette.grey3.main}`,
} as const);

const optionalCardStyle = (theme: Theme) => ({
  marginTop: theme.spacing(3),
  marginBottom: '0px',
  height: 'fit-content',
} as const);

export interface HDSetupProps {
  dashboardLayout?: boolean;
  hideDoneState?: boolean;
}

export interface HDSetupChildCardsProps extends HDSetupProps {
  children?: JSX.Element;
}

export function HDSetupFlowCard({ hideDoneState, dashboardLayout = false }: HDSetupProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isDesktopScreen = useMediaQuery(() => theme.breakpoints.up('lg'));
  const hdMemberRelation = useAppSelector(selectHomeDepotMemberRelation);
  const hdFlowState = useAppSelector(selectHDSetupFlowStepsCompletionState);
  const [HDOnboardingFlowComponent, setHdOnboardingFlowComponent] =
    useState<React.FC | null>(null);
  const [verificationStatus, setVerificationStatus] = useState(HDVerificationStatus.Pending);
  const trackOnboardEvent = useOnboardEventTracking();

  let buttonText: string;
  let nextStepToUpdate: HDSetupFlowSteps;

  const confirmDone = () => {
    dispatch(UpdateHDSetupFlowStepsCompletionState(nextStepToUpdate));

    if (nextStepToUpdate === HDSetupFlowSteps.AllInstructionsSeen) {
      // Populate user event
      trackOnboardEvent('clicked-hd-card-setup-done');
    }

    if (nextStepToUpdate === HDSetupFlowSteps.Done) {
      // Populate user event
      trackOnboardEvent('clicked-hd-card-after-setup-dismiss');
    }
  };

  const CTAComponent = () => (<CallToActionButton
    text={buttonText}
    type={'submit'}
    onBtnClick={confirmDone}
    optionalStackStyles={!dashboardLayout ? {
      display: 'flex',
    } : undefined}
    sx={{
      position: 'static',
      width: '100% !important',
      background: theme.palette.secondary.main,
      borderRadius: theme.spacing(3.75),
      fontWeight: '600',
      fontSize: `${theme.spacing(2)} !important`,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&:disabled': {
        backgroundColor: theme.palette.secondary.main,
        opacity: '0.4',
        color: theme.palette.common.white,
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: '0px',
      },
      ...(!dashboardLayout && {
        [theme.breakpoints.up('sm')]: {
          marginLeft: '0px',
        },
      }),
    }}
  />);
  useEffect(() => {
    if (!hdMemberRelation) {
      dispatch(getHomeDepotMemberRelation());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hdMemberRelation) {
      if (hdMemberRelation?.partnerMemberKey) {
        // Done state
        nextStepToUpdate = HDSetupFlowSteps.Done;
        setHdOnboardingFlowComponent(() => (
          !hideDoneState ? () => <HDSetupDoneCard /> : null
        ));
        setVerificationStatus(HDVerificationStatus.Completed);
      } else if (hdMemberRelation?.partnerMemberEmail) {
        switch (hdFlowState) {
          case HDSetupFlowSteps.EmailCaptured:
            nextStepToUpdate = HDSetupFlowSteps.Done;
            setHdOnboardingFlowComponent(() => (
              !hideDoneState ? () => <HDSetupDoneCard /> : null
            ));
            setVerificationStatus(HDVerificationStatus.Completed);
            break;
          case HDSetupFlowSteps.Done:
            if (dashboardLayout) {
              setHdOnboardingFlowComponent(() => null);
            } else {
              setHdOnboardingFlowComponent(() => (
                () => <HDEmailAddressCard dashboardLayout={dashboardLayout} />
              ));
            }
            setVerificationStatus(HDVerificationStatus.Pending);
            break;
          default:
            if (!dashboardLayout) {
              setHdOnboardingFlowComponent(() => (
                !hideDoneState ? () => <HDSetupDoneCard /> : null
              ));
              setVerificationStatus(HDVerificationStatus.Completed);
            }
        }
      } else if (hdMemberRelation?.partnerCodeEnteredInMobile) {
        setHdOnboardingFlowComponent(() => (
          () => <HDEmailAddressCard />
        ));
        setVerificationStatus(HDVerificationStatus.Pending);
      } else {
        switch (hdFlowState) {
          case HDSetupFlowSteps.Step3:
            buttonText = `${t('homeDepot.instructions.completionYes')}`;
            nextStepToUpdate = HDSetupFlowSteps.AllInstructionsSeen;
            setHdOnboardingFlowComponent(() => (
              () => <HDStepsCompleteConfirmationCard dashboardLayout={dashboardLayout}>
                <CTAComponent />
              </HDStepsCompleteConfirmationCard>
            ));
            break;
          case HDSetupFlowSteps.AllInstructionsSeen:
            setHdOnboardingFlowComponent(() => (
              () => <HDEmailAddressCard dashboardLayout={dashboardLayout} />
            ));
            break;
          default: setHdOnboardingFlowComponent(() => (
            () => <HDSetupStartCard dashboardLayout={dashboardLayout} />
          ));
        }
        setVerificationStatus(HDVerificationStatus.Pending);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hdFlowState, hdMemberRelation]);

  return (
    <>
      {
        HDOnboardingFlowComponent ? <Box
          sx={isDesktopScreen ? [cardStyle, optionalCardStyle] : [cardStyle]}
        >
          <HDSetupCardHeader verificationStatus={verificationStatus}
            dashboardLayout={dashboardLayout} />
          < HDOnboardingFlowComponent />
        </Box > : null
      }
    </>
  );
}
