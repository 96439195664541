import React, { useEffect } from 'react';
import {
  Box, Theme, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { OnboardForm } from './OnboardForm';
import OnboardingLayout from './common/OnboardingLayout';
import { OnboardBusinessInformationWrapper } from '../BusinessInformation/OnboardBusinessInformationWrapper';
import { OnboardingCompanyInformationWrapper } from '../CompanyInformation/OnboardingCompanyInformationWrapper';
import { OnboardingPartnersInformation } from '../OnboardingPartnersInformation';
import { MarketoForm } from '../Marketo/MarketoForm';
import { MktoForms2Type } from '../../../common/models/Marketo';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { PageTransition } from './common/PageTransition';
import { useTracking } from '../../../common/useTracking';
import { useSolutionOrg } from '../../../config/useSolutionOrg';
import { getOnboardingForm } from '../../../store/thunks/onboardingThunk';
import { useCompanyEnrollmentInfo } from '../../../common/useCompanyEnrollmentInfo';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';
import { changeFormIndex, selectCurrentFormIndex, setIsBackToPrevStep } from '../../../store/slices/onboardingSlice';
import { ReactComponent as BackArrow } from '../../../assets/images/arrow_back.svg';

interface OnboardHomeProps {
  optionalContainerStyle?: (theme: Theme) => SystemStyleObject<Theme>;
}

export function OnboardHome({
  optionalContainerStyle,
}: OnboardHomeProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOnboarding, setIsOnboarding] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const trackEvent = useTracking();
  const trackOnboardEvent = useOnboardEventTracking();
  const currentFormIndex = useAppSelector(selectCurrentFormIndex);
  const {
    envSolutionOrgFormId,
    numberOfOnboardingForms,
    isIframe,
    solutionOrgHasOnlyHomeDepot,
  } = useSolutionOrg();
  const onboardFormId = envSolutionOrgFormId as string;

  // check company enrollment status
  useCompanyEnrollmentInfo({ calledFromOnboardingComponent: true });

  useEffect(() => {
    dispatch(getOnboardingForm(
      {
        formId: onboardFormId,
        trackEvent,
      },
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitMarketoData = (email: string) => {
    const mktoForms2 = (window as unknown as Window & { MktoForms2?: MktoForms2Type }).MktoForms2;
    if (mktoForms2) {
      try {
        const myForm = mktoForms2.allForms()[0];
        myForm.addHiddenFields({
          // These are the values which will be submitted to Marketo
          Email: email,
        });

        myForm.onSuccess((submittedValues: object, followUpUrl: string | null) => {
          trackOnboardEvent('rewards_marketo_data_submission_succeeded');
          return false;
        });
        // submit form
        myForm.submit();
      } catch (err) {
        trackOnboardEvent('rewards_marketo_data_submission_failed', { error: err });
      }
    } else {
      trackOnboardEvent('rewards_marketo_form_loading_failed');
    }
  };

  const onPreviousStepClick = () => {
    // go to previous form
    dispatch(changeFormIndex(currentFormIndex - 1));
    dispatch(setIsBackToPrevStep(true));
  };

  const OnboardHomeComponent = (
    <OnboardingLayout reduceMobileTopPadding={isIframe}>
      <Box>
        {
          <>
            <OnboardForm
              // eslint-disable-next-line no-nested-ternary
              customBusinessInfoBtnText={solutionOrgHasOnlyHomeDepot ?
                (isIframe ? `${t('button.next')}` : `${t('button.done')}`) : ''
              }
              onboardFormId={onboardFormId || ''}
              numberOfQuestions={numberOfOnboardingForms as number}
              companyInformationComponent={OnboardingCompanyInformationWrapper}
              businessInformationComponent={() => <OnboardBusinessInformationWrapper />}
              createPartnersInformationComponent={
                (setButtonText?: (text: string) => void) => () => (<OnboardingPartnersInformation
                  setIsOnboarding={optionalContainerStyle ? setIsOnboarding : null}
                  submitMarketoData={submitMarketoData}
                  setButtonText={setButtonText}
                />)
              }
              optionalBtnStyles={{
                background: theme.palette.secondary.dark,
                borderRadius: theme.spacing(1.5),
                '&:hover': {
                  backgroundColor: theme.palette.secondary.dark,
                },
                '&:disabled': {
                  backgroundColor: theme.palette.secondary.dark,
                },
                marginTop: theme.spacing(1),
              }}
              noFooterText
            />
            {currentFormIndex === 2 && <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              mt: theme.spacing(2.5),
            }}
            >
              <Box
              onClick={onPreviousStepClick}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                cursor: 'pointer',
                width: 'fit-content',
              }}
              >
              <BackArrow/>
              <Typography component='div'sx={{
                fontWeight: 600,
                color: theme.palette.grey4.main,
                ml: theme.spacing(1.25),
              }}
              >
              {t('button.backToPrevStep')}
              </Typography>
              </Box>
            </Box>}
            <MarketoForm
              baseUrl='//lp.servicefusion.com'
              munchkinId='516-ZLM-498'
              formId={1742}
            />
          </>
        }
      </Box>
    </OnboardingLayout>
  );

  return (
    isDesktop ?
      <PageTransition>
        {OnboardHomeComponent}
      </PageTransition> :
      OnboardHomeComponent
  );
}
