/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { Content } from '../../common/models/content/Content';
import { FormAnswer } from '../../common/models/FormAnswer';
import type { RootState } from '../storeSetup';
import {
  getContent,
  getContentFormAnswers,
  getContentStatus,
  getFollowTopics,
  getTopTopics,
  addFollowTopic,
  deleteFollowTopic,
} from '../thunks/contentThunk';
import { FollowTopic } from '../../common/models/FollowTopic';
import { TopicsCount } from '../../common/models/content/TopicsCount';

interface ContentState {
  contentList: Content[];
  getContentListState: 'idle' | 'pending';
  getFollowTopicsState: 'idle' | 'pending';
  getTopTopicsState: 'idle' | 'pending';
  addFollowTopicState: 'idle' | 'pending';
  deleteFollowTopicState: 'idle' | 'pending';
  surveyFormAnswers: FormAnswer[];
  pageNumber: number;
  isBackNavigation: boolean;
  hasMoreContent: boolean;
  scrollPosition: number;
  isContentPreview: boolean;
  previewContentId: number;
  searchHistory: string[];
  selectedTopicIndex: number;
  followTopicsCardViewCount: number;
  behrPromotionCardViewCount: number;
  followTopics: FollowTopic[]
  topTopics: TopicsCount[]
}

// Define the initial state using that type
const initialState: ContentState = {
  contentList: [],
  getContentListState: 'pending',
  getFollowTopicsState: 'pending',
  getTopTopicsState: 'pending',
  addFollowTopicState: 'idle',
  deleteFollowTopicState: 'idle',
  surveyFormAnswers: [],
  pageNumber: 1,
  isBackNavigation: false,
  hasMoreContent: false,
  scrollPosition: 0,
  isContentPreview: false,
  previewContentId: 0,
  searchHistory: [],
  selectedTopicIndex: -1,
  followTopicsCardViewCount: 0,
  behrPromotionCardViewCount: 0,
  followTopics: [],
  topTopics: [],
};

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    ResetContentList: (state, action: PayloadAction<Content[]>) => {
      state.contentList = action.payload;
    },
    UpdatePageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    UpdateFollowTopicsCardViewCount: (state, action: PayloadAction<number>) => {
      state.followTopicsCardViewCount = action.payload;
    },
    UpdateBehrPromotionCardViewCount: (state, action: PayloadAction<number>) => {
      state.behrPromotionCardViewCount = action.payload;
    },
    UpdateIsBackNavigation: (state, action: PayloadAction<boolean>) => {
      state.isBackNavigation = action.payload;
    },
    UpdateHasMoreContent: (state, action: PayloadAction<boolean>) => {
      state.hasMoreContent = action.payload;
    },
    UpdateScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
    removePollAnswer: (state, action: PayloadAction<string>) => {
      state.surveyFormAnswers =
        state.surveyFormAnswers.filter((item) => item.id !== action.payload);
    },
    UpdateIsContentPreview: (state, action: PayloadAction<boolean>) => {
      state.isContentPreview = action.payload;
    },
    UpdatePreviewContentId: (state, action: PayloadAction<number>) => {
      state.previewContentId = action.payload;
    },
    PushSearchHistory: (state, action: PayloadAction<string>) => {
      if (action.payload && !state.searchHistory.includes(action.payload)) {
        // Keep latest 5 items
        state.searchHistory = [action.payload].concat(state.searchHistory.slice(0, 4));
      }
    },
    UpdateSelectedTopicIndex: (state, action: PayloadAction<number>) => {
      state.selectedTopicIndex = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getContent.pending, (state, action) => {
        state.getContentListState = 'pending';
      })
      .addCase(getContent.fulfilled, (state, action) => {
        const newContentList = [...state.contentList, ...action.payload];
        state.contentList = newContentList.filter((value, index, self) => (
          index === self.findIndex((t) => (t.id === value.id))
        ));
        state.getContentListState = 'idle';
      })
      .addCase(getContent.rejected, (state, action) => {
        state.getContentListState = 'idle';
      })
      .addCase(getContentStatus.fulfilled, (state, action) => {
        const copyContentList = cloneDeep(state.contentList);
        state.contentList = copyContentList.map((content) => {
          const likeStatus = action.payload[`${content.id}`];
          if (likeStatus) {
            return { ...content, likeStatus };
          }
          return content;
        });
      })
      .addCase(getContentFormAnswers.fulfilled, (state, action) => {
        const newSurveyFormAnswers = [...state.surveyFormAnswers, ...action.payload];
        state.surveyFormAnswers = newSurveyFormAnswers.filter((value, index, self) => (
          index === self.findIndex((t) => (t.id === value.id))
        ));
      })
      .addCase(getFollowTopics.pending, (state, action) => {
        state.getFollowTopicsState = 'pending';
      })
      .addCase(getFollowTopics.fulfilled, (state, action) => {
        state.followTopics = action.payload;
        state.getFollowTopicsState = 'idle';
      })
      .addCase(getFollowTopics.rejected, (state, action) => {
        state.getFollowTopicsState = 'idle';
      })
      .addCase(addFollowTopic.pending, (state) => {
        state.addFollowTopicState = 'pending';
      })
      .addCase(addFollowTopic.fulfilled, (state, action) => {
        const newFollowTopics = action.payload;
        state.followTopics = newFollowTopics;
        state.addFollowTopicState = 'idle';
      })
      .addCase(addFollowTopic.rejected, (state, action) => {
        state.addFollowTopicState = 'idle';
      })
      .addCase(deleteFollowTopic.pending, (state) => {
        state.deleteFollowTopicState = 'pending';
      })
      .addCase(deleteFollowTopic.fulfilled, (state, action) => {
        const newFollowTopics = action.payload;
        state.followTopics = newFollowTopics;
        state.deleteFollowTopicState = 'idle';
      })
      .addCase(deleteFollowTopic.rejected, (state, action) => {
        state.deleteFollowTopicState = 'idle';
      })
      .addCase(getTopTopics.pending, (state, action) => {
        state.getTopTopicsState = 'pending';
      })
      .addCase(getTopTopics.fulfilled, (state, action) => {
        state.topTopics = action.payload;
        state.getTopTopicsState = 'idle';
      })
      .addCase(getTopTopics.rejected, (state, action) => {
        state.getTopTopicsState = 'idle';
      });
  },
});

export const {
  ResetContentList,
  UpdatePageNumber,
  UpdateIsBackNavigation,
  UpdateHasMoreContent,
  UpdateScrollPosition,
  removePollAnswer,
  UpdateIsContentPreview,
  UpdatePreviewContentId,
  PushSearchHistory,
  UpdateSelectedTopicIndex,
  UpdateFollowTopicsCardViewCount,
  UpdateBehrPromotionCardViewCount,
} = contentSlice.actions;

export const selectContentList =
  (state: RootState) => state.content.contentList;
export const selectGetContentListState =
  (state: RootState) => state.content.getContentListState;
export const selectSurveyFormAnswers = (state: RootState) => state.content.surveyFormAnswers;
export const selectContentPageNumber = (state: RootState) => state.content.pageNumber;
export const selectIsBackNavigation = (state: RootState) => state.content.isBackNavigation;
export const selectHasMoreContent = (state: RootState) => state.content.hasMoreContent;
export const selectScrollPosition = (state: RootState) => state.content.scrollPosition;
export const selectIsContentPreview = (state: RootState) => state.content.isContentPreview;
export const selectPreviewContentId = (state: RootState) => state.content.previewContentId;
export const selectSearchHistory = (state: RootState) => state.content.searchHistory;
export const selectSelectedTopicIndex = (state: RootState) => state.content.selectedTopicIndex;
export const selectFollowTopicsCardViewCount =
  (state: RootState) => state.content.followTopicsCardViewCount;
export const selectBehrPromotionCardViewCount =
  (state: RootState) => state.content.behrPromotionCardViewCount;
export const selectGetFollowTopicsState = (state: RootState) => state.content.getFollowTopicsState;
export const selectGetTopTopicsState = (state: RootState) => state.content.getTopTopicsState;
export const selectFollowTopics = (state: RootState) => state.content.followTopics;
export const selectTopTopics = (state: RootState) => state.content.topTopics;

export default contentSlice.reducer;
