import React from 'react';
import {
  Box,
  Divider,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { AddMemberToPartnerInput } from '@optimus/models';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectHomeDepotMemberRelation, UpdateHDSetupFlowStepsCompletionState } from '../../../store/slices/dashboardSlice';
import { HDSetupFlowSteps } from '../../../common/enums';
import { updateMemberToPartner } from '../../../store/thunks/dashboardThunk';
import { selectMemberSession } from '../../../store/slices/onboardingSlice';
import { DialogPopup } from '../../../common/DialogPopup';
import { useOnboardEventTracking } from '../../../common/useOnboardEventTracking';

export default function HDSetupDoneCard() {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const trackOnboardEvent = useOnboardEventTracking();
  const { memberId } = useAppSelector(selectMemberSession);
  const hdMemberRelation = useAppSelector(selectHomeDepotMemberRelation);
  const [open, setOpen] = React.useState(false);
  const handleHdUnlink = () => {
    // reset partner member email, partner member key and partnerCodeEnteredInMobile
    const request: AddMemberToPartnerInput = {
      partnerMemberEmail: null,
      partnerCodeEnteredInMobile: false,
      partnerMemberKey: null,
    };

    dispatch(updateMemberToPartner({
      memberId,
      partnerId: process.env.REACT_APP_HOME_DEPOT_PARTNER_ID || '',
      data: request,
    })).then(unwrapResult)
      .then(() => {
        // Populate user event
        trackOnboardEvent('unlink-hd-email', {
          hdEmail: hdMemberRelation?.partnerMemberEmail,
        });
        dispatch(UpdateHDSetupFlowStepsCompletionState(
          HDSetupFlowSteps.None,
        ));
      });
  };

  return (
    <Box >
      <Box
        sx={{
          marginBottom: theme.spacing(2),
          marginTop: theme.spacing(2),
          color: theme.palette.grey56.main,
        }}
      >
        <Typography variant='body1'>
          {t('homeDepot.instructions.allSetupDone.description1')}
          <span style={{ fontWeight: 600 }}>{hdMemberRelation?.partnerMemberEmail}</span>
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          pt: theme.spacing(2),
        }}>
        <Typography>
          <span style={{ marginRight: theme.spacing(1) }}> {t('homeDepot.instructions.allSetupDone.description3')}</span>
          <span>
            <Link
              component="button"
              variant="body2"
              color={theme.palette.secondary.main}
              onClick={() => setOpen(true)}
            >
              {t('homeDepot.instructions.allSetupDone.description4')}
            </Link></span>
        </Typography>
      </Box>
      {
        open && <DialogPopup
          title={t('homeDepot.instructions.dialogConfirmation.title')}
          confirmBtnText={t('button.confirmAndLink')}
          cancelBtnText={t('button.cancel')}
          description={t('homeDepot.instructions.dialogConfirmation.description')}
          onBtnClick={handleHdUnlink}
          updateOpenState={(newState: boolean) => setOpen(newState)}
        />
      }
    </Box >
  );
}
