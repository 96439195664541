/* eslint-disable no-nested-ternary */
import {
  Box,
  Typography, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HomeDepotIcon } from '../../../assets/images/hd_logo.svg';
import { ReactComponent as PendingBadge } from '../../../assets/images/pending_badge.svg';
import { ReactComponent as CompletedBadge } from '../../../assets/images/completed_badge.svg';
import { ReactComponent as ErrorBadge } from '../../../assets/images/error_badge.svg';
import { HDVerificationStatus } from '../../../common/enums';

interface HDSetupCardHeaderProps {
  verificationStatus?: string;
  dashboardLayout?: boolean;
}

export default function HDSetupCardHeader({
  verificationStatus,
}: HDSetupCardHeaderProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const getVerificationStatusBadge = () => {
    switch (verificationStatus) {
      case HDVerificationStatus.Pending: return <PendingBadge />;
      case HDVerificationStatus.Completed: return <CompletedBadge />;
      case HDVerificationStatus.Error: return <ErrorBadge />;
      default: return null;
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
    }}>
      <HomeDepotIcon />
      <Box
        sx={{
          marginLeft: theme.spacing(3),
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4">
          {t('homeDepot.heading')}
        </Typography>
      </Box>
      <Box
        sx={{
          marginLeft: theme.spacing(3),
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {getVerificationStatusBadge()}
      </Box>
    </Box>
  );
}
